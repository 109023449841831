<template>
   <div class="page-form-FMC3">

      <div class="tab-pane__title">
         3/7. Тарифные планы
      </div>

      <div class="request__tabs">

          <b-tabs v-if="initialized" v-model="tabIndex">
              <b-tab v-if="false" lazy :title='"Конвергентный (" + tariffsConvergent.length +")"'>
                  <CardTariff v-show="pageFormFMC3" :tariffs="tariffsConvergent" v-model="convergentSelected"
                              @input="tariffOnInput" key="1"/>
              </b-tab>

              <b-tab v-if="tariffsHome.length" lazy :title='"Домашний интернет от МегаФон (" + tariffsHome.length +")"'>
                  <CardTariff v-show="pageFormFMC3" v-model="homeSelected" :tariffs="tariffsHome"
                              @input="tariffOnInput" key="2"/>
              </b-tab>

              <b-tab v-if="tariffsNoOverpayment.length" lazy :title='"Конвергентный (" + tariffsNoOverpayment.length +")"'>
                  <CardTariff v-show="pageFormFMC3" v-model="noOverpaymentSelected" :tariffs="tariffsNoOverpayment"
                              @input="tariffOnInput" key="3"/>
              </b-tab>
          </b-tabs>

          <TotalPriceFMC/>

          <div class="btns">
              <div class="btn back"
                   @click="prevStep()">
                  Назад
              </div>
              <div class="btn"
                   :class="{disabled: !isTariffSelected}"
                   @click="gotoNext">
                  Подтвердить
              </div>
          </div>

      </div>
   </div>
</template>

<script>
import CardTariff from "@/components/requestFMC/CardTariff";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TotalPriceFMC from "@/components/requestFMC/TotalPriceFMC";
import {showModalErrorDefault} from "@/utils/errors";

export default {
    name: "pageFormFMC3",

    components: {
        CardTariff,
        TotalPriceFMC
    },
    data() {
        return {
            initialized: false,
            tabIndex: 1,
            //tariffsConvergent: [],
            //tariffsHome: [],
            //homeSelected: null,
            //convergentSelected: null,
        }
    },
    computed: {
        ...mapGetters([
            //'tariffsConvergent',
            //'tariffsHome',
            'pageFormFMC3'
        ]),

        ...mapGetters('clientInfo', ['clientInfo']),
        ...mapGetters('segment', ['segmentID']),

        isTariffSelected() {
            return this.homeSelected !== null || this.convergentSelected !== null || this.noOverpaymentSelected !== null
        },

        tariffsConvergent: {
            get() {
                console.log('THIS IS', this)
                return this.$store.state.FMC.tariffsConvergent
            },
            set(newVal) {
                this.$store.state.FMC.tariffsConvergent = newVal
            }
        },

        tariffsHome: {
            get() {
                return this.$store.state.FMC.tariffsHome
            },
            set(newVal) {
                this.$store.state.FMC.tariffsHome = newVal
            }
        },

        tariffsNoOverpayment: {
            get() {
                console.log('THIS IS', this)
                return this.$store.state.FMC.tariffsNoOverpayment
            },
            set(newVal) {
                this.$store.state.FMC.tariffsNoOverpayment = newVal
            }
        },

        ...mapState({
            existingClientGuid: (state => state.FMC.existingClientGuid),
            addressInfo: ({FMC}) => FMC.addressInfo,
        }),

        homeSelected: {
            get() {
                return this.$store.state.FMC.homeSelected
            },
            set(newVal) {
                this.$store.state.FMC.convergentSelected = null
                this.$store.state.FMC.noOverpaymentSelected = null
                this.$store.state.FMC.homeSelected = newVal
                console.log(this.$store.state.FMC)
            }
        },
        convergentSelected: {
            get() {
                return this.$store.state.FMC.convergentSelected
            },
            set(newVal) {
                this.$store.state.FMC.homeSelected = null
                this.$store.state.FMC.noOverpaymentSelected = null
                console.log('convergent sel', newVal)
                this.$store.state.FMC.convergentSelected = newVal
            }
        },
        noOverpaymentSelected: {
            get() {
                return this.$store.state.FMC.noOverpaymentSelected
            },
            set(newVal) {
                this.$store.state.FMC.homeSelected = null
                this.$store.state.FMC.convergentSelected = null
                console.log('no overpayment sel', newVal)
                this.$store.state.FMC.noOverpaymentSelected = newVal
            }
        },

        productID() {
            return this.homeSelected?.id || this.convergentSelected?.id || this.noOverpaymentSelected?.id
        },
        productPrice() {
            return this.homeSelected?.price || this.convergentSelected?.price || this.noOverpaymentSelected?.price || 0
        }
    },
    methods: {
        ...mapMutations([
            'GO_TO_PAGE_FMC'
        ]),
        ...mapActions(['doGetProducts']),

        async loadTariffs() {
            await this.doGetProducts({
                gm_localityID: this.$store.state.FMC.addressInfo.addressId,
                segment: this.segmentID,
                account: this.clientInfo.account ?? 0,
                subscriberId: this.clientInfo.subscriberId,
                current: this.clientInfo.productId ?? undefined,
                familyAvailable: this.clientInfo.familyAvailable,
                isPrivateDistrict: this.addressInfo.isPrivateDistrict
            })
                .then(r => {
                    this.parseCurrentsFromRequest(r.data)
                    this.parseProductsFromRequest(r.data)
                    if(this.clientInfo.productId) {
                        this.$store.commit('clientInfo/UPDATE_CLIENT_INFO', {
                            resetGroup: true,
                        })
                    }
                })
                .catch((r) => {
                    showModalErrorDefault(r.message)
                })

            this.selectTariff()

            this.initialized = true
        },
        selectTariff() {
            let selectIndex = 0
            let selectKind = null
            this.tariffsNoOverpayment.forEach((tariff, index) => {
                console.log("index = " + index)
                if (tariff.id === this.clientInfo.productId) {
                    if (tariff.selectable) {
                        this.noOverpaymentSelected = tariff
                        this.tariffOnInput(tariff)
                    }

                    this.tabIndex = 2
                    selectIndex = index
                    selectKind = "no_overpayment"
                }
            })
            this.tariffsConvergent.forEach((tariff, index) => {
                console.log("index = " + index)
                if (tariff.id === this.clientInfo.productId) {
                    // if (tariff.selectable) {
                    //   this.convergentSelected = tariff
                    //   this.tariffOnInput(tariff)
                    // }
                    this.tabIndex = 0
                    selectIndex = index
                    selectKind = "convergent"
                }
            })
            if (selectIndex !== 0) {
                console.log("selKind", selectKind)
                if (selectKind === "no_overpayment") {
                    // const firstArr = this.tariffsNoOverpayment.slice(selectIndex)
                    // console.log("1arr", firstArr)
                    // const twoArr = this.tariffsNoOverpayment.slice(0, this.tariffsNoOverpayment.length - selectIndex)
                    // console.log("2arr", twoArr)
                    // this.tariffsNoOverpayment = [...firstArr, ...twoArr]

                    this.tariffsNoOverpayment.unshift(this.tariffsNoOverpayment.splice(selectIndex, 1)[0])

                } else if (selectKind === "convergent") {
                    // const firstArr = this.tariffsConvergent.slice(selectIndex)
                    // console.log("1arr", selectKind)
                    // const twoArr = this.tariffsConvergent.slice(0, this.tariffsConvergent.length - selectIndex)
                    // console.log("2arr", selectKind)
                    // this.tariffsConvergent = [...firstArr, ...twoArr]
                    this.tariffsConvergent.unshift(this.tariffsConvergent.splice(selectIndex, 1)[0])
                }

            }
        },
        parseTariffItem(item) {
            console.log(item)
            const content = item.content
            return {
                id: item.id,
                name: item.name,
                price: item.cost,
                recommend: item.recommend,
                type: item.type,
                call: content.calls?.name,
                mobInternet: content.mobile?.name,
                homeInternet: content.internet?.name.split('</br>'),
                megaFamily: content.megafamily?.description,
                tvChannels: content.channels?.name,
                services: content.equipments?.content,
                source: item,
                discountsHint: item.discountsHint,
                discounts: item.discounts,
                selectable: item.selectable ?? true,
            }
        },
        extractConvergentTariffsFromResponseData(data) {
            return data.tariffs.filter((data) => (data.main_id === 0 && data.type === 'FMC'))
                .map(item => this.parseTariffItem(item))
        },
        extractHomeTariffsFromResponseData(data) {
            return data.tariffs.filter((data) => (data.main_id === 3751))
                .map(item => this.parseTariffItem(item))
        },
        extractNoOverpaymentTariffsFromResponseData(data) {
            return data.tariffs.filter((data) => (data.main_id === 0 && data.type === 'NO_OVERPAYMENT'))
                .map(item => this.parseTariffItem(item))
        },
        parseProductsFromRequest(data) {
            console.log(data)
            this.tariffsConvergent = this.extractConvergentTariffsFromResponseData(data)
            this.tariffsHome = this.extractHomeTariffsFromResponseData(data)
            this.tariffsNoOverpayment = this.extractNoOverpaymentTariffsFromResponseData(data)

        },
        parseCurrentsFromRequest(data) {
            const currentEquip = data.current.equipment ?? []
            let currentTariff = null

            if (data.current) {
                currentTariff = {
                    internet: data.current.internet,
                    channels: data.current.channels,
                    lte: data.current.lte,
                    abonpay: data.current.abonpay,
                }
                if (
                    !!currentTariff.internet ||
                    !!currentTariff.channels ||
                    !!currentTariff.lte
                ) {
                    this.$store.commit('SET_CURRENT_TARIFF_PARAMS', currentTariff)
                } else {
                    this.$store.commit('SET_CURRENT_TARIFF_PARAMS', null)
                }
            }

            this.$store.commit('SET_EQUIPMENT_TO_RETURN', currentEquip)
        },
        saveToStore() {
            //alert(this.productID)
            //alert(this.productPrice)

            // this.$store.state.FMC.tariffInfo.productID = this.productID
            // this.$store.state.FMC.tariffInfo.price = this.productPrice
        },

        gotoNext() {
            if (!this.isTariffSelected) return
            this.GO_TO_PAGE_FMC(['pageFormFMC3', 'pageFormFMC4'])
        },

        tariffOnInput(tariff) {
            this.$store.commit('rent/ROLLBACK')
            this.$store.commit('fix/ROLLBACK')
            this.$store.commit('installment/ROLLBACK')
            this.$store.commit('mobile/ROLLBACK')
            this.$store.commit('sim/ROLLBACK')
            console.log(tariff)
            this.$store.state.FMC.tariffInfo.name = tariff.name
            this.$store.state.FMC.tariffInfo.productID = tariff.id
            this.$store.state.FMC.tariffInfo.price = tariff.price
            this.$store.state.FMC.tariffInfo.type = tariff.type
            this.$store.state.FMC.tariffInfo.source = tariff.source
            this.$store.state.FMC.tariffInfo.mainProductID = tariff.source.main_id
        },

        prevStep() {
            this.$store.commit('installment/ROLLBACK')
            this.$store.commit('rent/ROLLBACK')
            this.$store.commit('fix/ROLLBACK')
            this.$store.commit('mobile/ROLLBACK')
            this.$store.commit('sim/ROLLBACK')
            this.$store.state.FMC.convergentSelected = null
            this.$store.state.FMC.noOverpaymentSelected = null
            this.$store.state.FMC.homeSelected = null
            this.GO_TO_PAGE_FMC(['pageFormFMC3', 'pageFormFMC2'])
        }
    },
    mounted() {
        this.loadTariffs()
    },

}
</script>

<style lang="scss">
   .btns {
      display: flex;
      justify-content: space-between;
   }
</style>
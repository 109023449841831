import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import requestCRM from "@/views/requests/requestCRM";
import requestFMC from "@/views/requests/requestFMC";
import login from "@/views/login/login";
import changePassword from "@/views/login/changePassword";
import notifications from "@/views/notifications";
import orders from "@/views/orders";
import viewingAct from "@/views/requests/viewingAct";
import viewing_users from "@/views/create_users/viewing_users";
import viewing_dealers from "@/views/organization/viewing_dealers";
import viewing_news from "@/views/news/viewing_news";
import packages from "@/views/organization/packages";
import tariffs from "@/views/organization/tariffs";
import viewing_dealers_data from "@/views/organization/viewing_dealers_data";
import viewing_dealers_change from "@/views/organization/viewing_dealers_change";
import auth from "@/views/auth/auth";
import Recovery from "@/components/auth/Recovery";
import viewingRequests from "@/views/requests/viewingRequests/viewingRequests";
import viewingRequests_more from "@/views/requests/viewingRequests/viewingRequests_more";
import store from '@/store';
import requestOEveryWhere from "@/views/requests/requestOEveryWhere";
import viewingUniteRequests_more from "@/views/requests/viewingRequests/viewingUniteRequests_more";
import viewingFMCRequests_more from "@/views/requests/viewingRequests/viewingFMCRequests_more";
import viewingXRMRequests_more from "@/views/requests/viewingRequests/viewingXRMRequests_more";
import suspended_news from "@/views/news/suspended_news";
import documents from "@/views/documents.vue";
import files from "@/views/files.vue";

Vue.use( Router )

//const isNeedAnyAuthActions = store.getters['user/isNeedAnyAuthActions']

let checkNotificationsTicker = null

const ifAuthenticated = (to, from, next) => {
   if (!store.getters['user/isNeedAnyAuthActions']) {
      if(!checkNotificationsTicker) {
         store.dispatch('user/doCheckUserHasNotifications', null, {root: true})
         checkNotificationsTicker = setInterval(() => store.dispatch('user/doCheckUserHasNotifications', null, {root: true}), 600000)
      }
      next()
     return
   }

   console.log('redir because not authed')
   next('/auth')

}

const ifNotAuthenticated = (to, from, next) => {
   if (store.getters['user/isNeedAnyAuthActions']) {
      next()
      return
   }
   next('/')
}

const redir = (url) => {
   return ({params}) => {
      const qParams = new URLSearchParams(params).toString()
      window.location.href = `${url}${qParams ? '?' : ''}${qParams}`
   }
}

// eslint-disable-next-line no-unused-vars
//  const afterEach = (from, to) => {
//    console.log('aftereach', from, to)
//
//     if (to.match('/auth')) {
//        console.log('matched auth')
//     }
// }

const router = new Router({
   base: process.env.NODE_ENV === 'development' ? '/new' : '/new',
   mode: 'history',
   routes: [
      {
         path: '/auth',
         name: 'auth',
         component: auth,
         beforeEnter: ifNotAuthenticated,
         children: [
            {
               path: 'recovery',
               name: 'Recovery',
               component: Recovery,
            },
         ]
      }, {
         path: '/',
         name: 'home',
         component: Home,
         beforeEnter: ifAuthenticated
      }, {
         path: '/request-CRM',
         name: 'requestCRM',
         //beforeEnter: redir('/v2/xdemands/new/'),
         beforeEnter: ifAuthenticated,
         component: requestCRM,
      },
      {
         path: '/request-CRM/view/:guid',
         name: 'viewingXRMRequest',
         component: viewingXRMRequests_more,
         beforeEnter: ifAuthenticated,
         //beforeEnter: redir('/v2/xdemands/detail/'),
      },
       {
           path: '/request-FMC',
           name: 'requestFMC',
           component: requestFMC,
          beforeEnter: ifAuthenticated,
          //beforeEnter: redir('/v2/fdemands/new/'),
       },
      {
         path: '/request-OEveryWhere',
         name: 'requestOEveryWhere',
         component: requestOEveryWhere,
         beforeEnter: ifAuthenticated,
         //beforeEnter: redir('/v2/fdemands/new/'),
      },
      {
         path: '/request-OEveryWhere/view/:id',
         name: 'viewingUniteRequest',
         component: viewingUniteRequests_more,
         beforeEnter: ifAuthenticated,
         //beforeEnter: redir('/v2/odemands/detail/'),
      },
      {
         path: '/request-FMC/view/:guid',
         name: 'viewingFMCRequest',
         component: viewingFMCRequests_more,
         beforeEnter: ifAuthenticated,
         //beforeEnter: redir('/v2/fdemands/detail/'),
      },
      {
         path: '/upload-xdemands',
         name: 'uploadingXDemands',
         beforeEnter: redir('/v2/xdemands/upload'),
      },
      {
         path: '/users/add',
         name: 'adding_user',
         beforeEnter: redir('/retail/users/insert/'),
      },
      {
         path: '/login',
         name: 'login',
         component: login,
         beforeEnter: ifAuthenticated,
      }, {
         path: '/change-password',
         name: 'changePassword',
         component: changePassword,
         beforeEnter: ifAuthenticated,
      }, {
         path: '/notifications',
         name: 'notifications',
         component: notifications,
         beforeEnter: ifAuthenticated,
      }, {
         path: '/orders',
         name: 'orders',
         component: orders,
         beforeEnter: ifAuthenticated,
      },{
         path: '/documents',
         name: 'documents',
         component: documents,
         beforeEnter: ifAuthenticated,
      }, {
         path: '/files',
         name: 'files',
         component: files,
         beforeEnter: ifAuthenticated,
      }, {
         path: '/viewingRequests',
         name: 'viewingRequests',
         component: viewingRequests,
         //beforeEnter: redir('/v2/fdemands/list'),
      }, {
         path: '/viewingRequests/more',
         name: 'viewingRequests_more',
         component: viewingRequests_more
      }, {
         path: '/viewingAct',
         name: 'viewingAct',
         component: viewingAct,
         //beforeEnter: redir('/v2/xreports/xrmdemands'),
      }, {
         path: '/viewing_users',
         name: 'viewing_users',
         component: viewing_users,
         beforeEnter: redir('/retail/users/list/'),
      }, {
         path: '/polls',
         name: 'polls',
         beforeEnter: redir('/v2/polls/uploads'),
      },
      {
         path: '/news',
         name: 'news',
         component: viewing_news,
         beforeEnter: ifAuthenticated,
      },

      {
         path: '/news/suspended',
         name: 'news_suspended',
         component: suspended_news,
         beforeEnter: ifAuthenticated,
      },

      {
         path: '/news/rate_report/:news_id',
         name: 'news_rate_report',
         beforeEnter: redir('/v2/news/report'),
      },

      // Управление дилерами и тарифами

      {
         path: '/dealers/add',
         name: 'dealers_add',
         //component: ,
         beforeEnter: redir('/retail/dealers/insert/'),
      },

      {
         path: '/dealers',
         name: 'viewing_dealers',
         component: viewing_dealers,
         beforeEnter: redir('/retail/dealers/list/'),
      },

      {
         path: '/packages',
         name: 'packages',
         component: packages,
         beforeEnter: redir('/retail/dealers/hiddenbundles/'),
      },

      {
         path: '/tariffs',
         name: 'tariffs',
         component: tariffs,
         beforeEnter: redir('/retail/dealers/hiddentariffs/'),
      },

      {
         path: '/fmc/discount/add',
         name: 'fmc_discount_add',
         //component: tariffs,
         beforeEnter: redir('/v2/fdemands/discountinsert'),
      },

      {
         path: '/fmc/discount/view',
         name: 'fmc_discount_view',
         //component: tariffs,
         beforeEnter: redir('/v2/fdemands/discountlist'),
      },


      {
         path: '/dealers/data',
         name: 'viewing_dealers_data',
         component: viewing_dealers_data
      },

      {
         path: '/dealers/change',
         name: 'viewing_dealers_change',
         component: viewing_dealers_change
      },

      // 100 мб на весь год

      {
         name: '100mb_add',
         path: '/100mb/add',
         beforeEnter: redir('/retail/demands/s100mb/')
      },

      {
         name: '100mb_view',
         path: '/100mb/view',
         beforeEnter: redir('/retail/demands/view100mbs/')
      },

      {
         name: '100mb_act',
         path: '/100mb/act',
         beforeEnter: redir('/v2/xreports/act100mbs2016')
      },

      // TV/Mobile аккаунты

      {
         name: 'tvmobile_add',
         path: '/tvmobile/add',
         beforeEnter: redir('/retail/demands/accountbuilder/')
      },

      {
         name: 'tvmobile_view',
         path: '/tvmobile/view',
         beforeEnter: redir('/retail/demands/viewaccounts/')
      },

      {
         name: 'tvmobile_act',
         path: '/tvmobile/act',
         beforeEnter: redir('/v2/xreports/actaccounts')
      },

      // Заявки Псков

      {
         name: 'request_kis',
         path: '/kis/request',
         beforeEnter: redir('/retail/demands/connect/'),
      },

      {
         name: 'request_kis_view',
         path: '/kis/viewdemands',
         beforeEnter: redir('/retail/demands/viewdemands/'),
      },

      {
         name: 'request_kis_existing',
         path: '/kis/addsales',
         beforeEnter: redir('/retail/demands/addsales/'),
      },

      {
         name: 'request_kis_existing_view',
         path: '/kis/viewaddsales',
         beforeEnter: redir('/retail/demands/viewaddsales/'),
      },

      {
         name: 'request_kis_act_created',
         path: '/kis/actorders',
         beforeEnter: redir('/v2/xreports/actorders'),
      },

      {
         name: 'request_kis_act_finished',
         path: '/kis/actorderssuccess',
         beforeEnter: redir('/v2/xreports/actorderssuccess'),
      },

      {
         name: 'request_kis_act_existing',
         path: '/kis/actaddsales',
         beforeEnter: redir('/v2/xreports/actaddsales'),
      },
   ]
})


//router.afterEach(afterEach)

export default router
<template>

  <div class="request-fmc-create">
    <form @keydown.enter.prevent @submit.prevent="submitFormFMC">

      <div class="tab-pane__title">
        1/7. Создание дома в АИСТ
      </div>

      <div class="tab-pane__input">
        <label class="star">Город</label>
        <!--                      <input type="text"-->
        <!--                             :disabled="formDisabledFMC"-->
        <!--                             v-model.trim="formFMC.city"-->
        <!--                             :class="{ invalid: validCity}"-->
        <!--                             @input="handleCityInput"-->
        <!--                      >-->
        <VueBootstrapTypeAhead
            class="addr-item"
            :data="formFMC.city.addressChoices"
            v-model="formFMC.city.addressSearch"
            :max-matches="40"
            :serializer="s => s.value"
            placeholder="Введите город..."
            @hit="formFMC.city.selected = $event.data.guid; formFMC.city.selectedText = $event.value;"
            @input="updateCities"
            :show-all-results="true"
            :disabled="!addressDataFMC"
            ref="cityInput"
        />
        <div class="spinner__wrap">
          <b-spinner v-show="loaders.city" variant="success" label="Spinning"></b-spinner>
        </div>

      </div>

      <div class="tab-pane__input">
        <!--                     <input type="text" :disabled="formDisabledFMC">-->
        <div class="wrapper_addr_block">
          <div class="wrapper_addr_item">
            <label>Улица</label>
            <div class="addr-item">
              <VueBootstrapTypeAhead
                  class="addr-item"
                  :data="formFMC.street.addressChoices"
                  v-model="formFMC.street.addressSearch"
                  :min-matching-chars="1"
                  :serializer="s => s.value"
                  placeholder="Введите улицу..."
                  @hit="formFMC.street.selected = $event.data.guid; formFMC.street.selectedText = $event.value"
                  @input="updateStreets"
                  input-class="addr-input"
                  :disabled="!addressDataFMC || this.loadList.street"
                  ref="streetInput"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane__input">
        <!--                     <input type="text"-->
        <!--                            :disabled="formDisabledFMC"-->
        <!--                            v-model.trim="formFMC.house"-->
        <!--                            :class="{ invalid: validHouse}"-->
        <!--                     >-->

        <div class="wrapper_addr_block">
          <div class="wrapper_addr_item">
            <label class="star">Дом</label>
            <div class="addr-item">
              <VueBootstrapTypeAhead
                  class="addr-item"
                  :data="formFMC.house.addressChoices"
                  v-model="formFMC.house.addressSearch"
                  :min-matching-chars="1"
                  :maxMatches="100"
                  :serializer="s => s.value"
                  placeholder="Введите номер дома..."
                  :disabled="!addressDataFMC || this.loadList.home"
                  :show-all-results="true"
                  ref="houseInput"
              />
            </div>
            <div class="spinner__wrap">
              <b-spinner v-show="loaders.home" variant="success" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>

      <div v-if="addressDataFMC && !formFMC.house.selected && formFMC.house.addressSearch" class="tab-pane__input">
        <label></label>
        <button class="btn">Подтвердить</button>
      </div>
    </form>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {integer, minValue, required} from "vuelidate/lib/validators";
//import ModalCongratulationsFMC from "@/components/modals/requestFMC/ModalCongratulationsFMC";
import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
import API from '@/api';
import axios from "axios";
import {debounce} from "lodash";
import {finishLoading, startLoading} from "@/utils/ui";
import api from "@/api";
import {checkForErrorByStatus, showModalErrorDefault} from "@/utils/errors";
//import SlotsInitializer from "@/components/requestFMC/SlotsInitializer";
//import ClientsTableCRM from "@/components/requestCRM/ClientsTableCRM";

export default {
  name: "requestFMCCreate",

  components: {
    //ModalCongratulationsFMC,
    VueBootstrapTypeAhead,
  },


  data() {
    const emptyAddr = this.emptyAddrItem()
    const house = {
      ...emptyAddr,
    }
    const street = {
      ...emptyAddr,
      children: [house]
    }
    const city = {
      ...emptyAddr,
      children: [street, house]
    }
    return {
      slotsKey: Math.random(),
      slotsOpened: false,

      hasConnections: false,
      preliminaryMessage: '',
      modalErrorIsUnite: false,

      home_comment: "-",
      company_comment: "-",
      home_project: "-",
      economic_zone: "-",
      investment_category_name: "-",
      investment_category_id: null,
      access_time_from: null,
      access_time_to: null,
      home_restricted: [],
      opportunity100: "Нет",
      opportunity100bool: false,
      technology: "-",
      technologyId: "",
      fvno_comment: "-",
      fias: "",
      isPrivateDistrict: null,
      isPrivateDistrictVal: this.isPrivateDistrict,

      addressStr: '',
      addressGuid: '',
      addressId: null,

      clientsList: [],
      incidentsList: [],

      showResult: true,

      loaders: {
        city: false,
        street: false,
        home: false,
      },

      formFMC: {
        city,
        street,
        house,
        apartment: '',
        isPrivateDistrictVal: false,
        isPrivateDistrict: false
      },

      formFMCNext: {
        front: '',
        level: '',
      },
      keys: {
        chooseDate: Math.random(),
        slotAlreadyAssigned: Math.random(),
      },
      incidentGuid: null,
      slotsData: {},
      datesAssigned: {
        desired: '',
        connection: '',
      },
      loadList: {
        street: false,
        house: false,
      },
      apartmentMask: {
        'N': {pattern: /[0-9a-zA-Zа-яА-Я]/}
      }
    }
  },

  computed: {
    ...mapGetters([
      'addressDataFMC',
      'showClientsListFMC',
      'formDisabledFMC',
      'predefinedAddress',
      // 'formFMC',
      // 'formFMCNext',
      // 'requestFMCCreate',
      'addressInfo',
    ]),
    ...mapGetters('test', [
      'connections'
    ]),
    validCity() {
      return (!this.$v.formFMC.city.selected.required) && this.$v.formFMC.city.selected.$dirty
    },
    validHouse() {
      return (!this.$v.formFMC.house.selected.required) && this.$v.formFMC.house.selected.$dirty
    },
    validFront() {
      if (this.formFMCNext.front === '')
        return false
      return (!this.$v.formFMCNext.front.integer || !this.$v.formFMCNext.front.minValue) && this.$v.formFMCNext.front.$dirty
    },
    validFloor() {
      if (this.formFMCNext.level === '')
        return false
      return ((!RegExp('[0-9]').test(this.formFMCNext.level) || !this.$v.formFMCNext.level.minValue)) && this.$v.formFMCNext.level.$dirty
    },
    validApartment() {
      if (!this.formFMC.isPrivateDistrictVal) {
        return this.$v.formFMC.apartment.$dirty
      }

      return false
    },
    addressWithApartment() {
      return this.addressStr + (this.formFMC.apartment ? `, кв. ${this.formFMC.apartment}` : '')
    },


    addressParams() {
      return {
        house_comment: this.house_comment,
        company_comment: this.company_comment,

        addressStr: this.addressStr,
        addressGuid: this.addressGuid,
        addressId: this.addressId,

        formFMC: this.formFMC
      }
    }
  },

  validations: {
    // formFMC: {
    //    city: {required, minLength: minLength( 3 )},
    //    house: {required},
    // },
    formFMC: {
      city: {
        selected: {required}
      },
      house: {},
      apartment: {},
    },

    formFMCNext: {
      front: {integer, minValue: minValue(1)},
      level: {integer, minValue: minValue(-999)}
    }
  },

  methods: {
    ...mapMutations([
      'BTN_FORM_DISABLED_FMC',
      'GO_TO_PAGE_FMC',
      'SET_PRELIMINARY',
      'SHOW_CLIENTS_FMC',
      'HIDE_CLIENTS_FMC',
      'CHANGE_FORM_FMC',
      'FMC_SET_EXISTING_CLIENT',
      'FMC_SET_NEW_CLIENT',
      'FMC_SET_ADDR_INFO',
    ]),
    ...mapMutations('clientInfo', [
      'UPDATE_CLIENT_INFO'
    ]),
    ...mapMutations('test', [
      'UPDATE'
    ]),
    ...mapMutations('segment', [
      'SET_FIXED',
    ]),
    ...mapActions(['doCheckAddress', 'doFindClientByFias', 'doGetClient', 'doCreateHouse']),
    emptyAddrItem() {
      return {
        addressSearch: '',
        selected: null,
        selectedText: '',
        addressChoices: [],
        fullList: [],
      }
    },

    saveToStore() {
      this.FMC_SET_ADDR_INFO({
        home_comment: this.home_comment,
        home_project: this.home_project,
        company_comment: this.company_comment,
        economic_zone: this.economic_zone,
        investment_category_name: this.investment_category_name ?? "-",
        investment_category_id: this.investment_category_id,
        access_time_from: this.access_time_from,
        access_time_to: this.access_time_to,
        home_restricted: this.home_restricted,
        addressStr: this.addressStr,
        addressGuid: this.addressGuid,
        addressId: this.addressId,
        regionId: this.formFMC.city.selected,
        apartment: this.formFMC.apartment,
        porch: this.formFMCNext.front,
        floor: this.formFMCNext.level,
        opportunity100: this.opportunity100,
        opportunity100bool: this.opportunity100bool,
        technology: this.technology,
        technologyId: this.technologyId,
        fvno_comment: this.fvno_comment,
        partnerId: this.partnerId,
        fias: this.fias,
        isPrivateDistrict: true
      })
    },

    resetChildren(item) {
      if (item.children === undefined) return
      const emptyItem = this.emptyAddrItem()
      item.children.forEach((child, idx) => {
        Object.keys(emptyItem).forEach((key) => {
          item.children[idx][key] = emptyItem[key]
        })
      })

    },

    updateCities: debounce(async function () {
      //this.city.selected = this.emptyAddrItem().selected
      this.resetChildren(this.formFMC.city)
      this.$refs.streetInput.inputValue = null
      this.$refs.houseInput.inputValue = null
      this.loaders.city = true
      const existItem = this.formFMC.city.addressChoices.find((item) => item.value === this.formFMC.city.addressSearch)
      if (!existItem) {
        //console.log(existItem)
        await API.fdemands.findCities(this.formFMC.city.addressSearch)
            .then(r => r.data)
            .then((r) => {
              this.formFMC.city.addressChoices = r.suggestions
            })
            .catch((e) => {
              if (axios.isCancel(e))
                console.log('canceled')

              console.log(e)
            })
      }

      this.loaders.city = false
    }, 1000),

    updateStreets: debounce(async function () {
      //this.street.selected = this.emptyAddrItem().selected

      this.loaders.street = true
      const existItem = this.formFMC.street.addressChoices.find((item) => item.value === this.formFMC.street.addressSearch)
      if (!existItem) {
        this.resetChildren(this.formFMC.street)
        this.$refs.houseInput.inputValue = null
        await API.fdemands.findStreets(this.formFMC.street.addressSearch, this.formFMC.city.selected)
            //.then((r) => { console.log(r) })
            .then(r => r.data)
            .then((r) => {
              this.formFMC.street.addressChoices = r.suggestions
            })
            .catch((e) => {
              if (axios.isCancel(e))
                console.log('canceled')

              console.log(e)
            })
      }
      this.loaders.street = false
    }, 1000),

    async showStreetsList() {
      if (this.formFMC.street.fullList.length > 0) {
        this.formFMC.street.fullList = [];
        return;
      }
      this.loaders.street = true
      this.loadList.street = true
      await API.fdemands.findStreets('', this.formFMC.city.selected, true)
          //.then((r) => { console.log(r) })
          .then(r => r.data)
          .then((r) => {
            this.formFMC.street.addressChoices = this.formFMC.street.fullList = r.suggestions
          })
          .catch((e) => {
            if (axios.isCancel(e))
              console.log('canceled')

            console.log(e)
          })

      this.loaders.street = false
      this.loadList.street = false
    },

    selectStreet(street) {
      this.$refs.streetInput.inputValue = street.value
      this.formFMC.street.selected = street.data.guid
      this.formFMC.street.selectedText = street.value
      this.formFMC.street.addressChoices = this.formFMC.street.fullList
      this.formFMC.street.fullList = []
      this.$refs.houseInput.inputValue = null
      this.resetChildren(this.formFMC.street)
    },

    updateHouses: debounce(async function () {
      //this.house.selected = this.emptyAddrItem().selected

      this.loaders.home = true
      const existItem = this.formFMC.house.addressChoices.find((item) => item.value === this.formFMC.house.addressSearch)
      if (!existItem) {
        this.resetChildren(this.formFMC.house)
        await API.fdemands.findHouses(this.formFMC.house.addressSearch, this.formFMC.city.selected, this.formFMC.street.selected)
            //.then((r) => { console.log(r) })
            .then(r => r.data)
            .then((r) => {
              this.formFMC.house.addressChoices = r.suggestions
            })
            .catch((e) => {
              if (axios.isCancel(e))
                console.log('canceled')

              console.log(e)
            })
      }
      this.loaders.home = false
    }, 1000),

    async showHousesList() {
      if (this.formFMC.house.fullList.length > 0) {
        this.formFMC.house.fullList = [];
        return;
      }
      this.loaders.home = true
      this.loadList.home = true
      await API.fdemands.findAllHouses(this.formFMC.city.selected, this.formFMC.street.selected)
          .then(r => r.data)
          .then((r) => {
            this.formFMC.house.addressChoices = this.formFMC.house.fullList = r.suggestions
          })
          .catch((e) => {
            if (axios.isCancel(e))
              console.log('canceled')

            console.log(e)
          })

      this.loaders.home = false
      this.loadList.home = false
    },

    async submitFormFMC() {
      if (this.$v.formFMC.$invalid) {
        this.$v.formFMC.$touch()
      } else {
        console.log(this.formFMC)
        const house = await this.createHouse(this.formFMC.street.selected ? this.formFMC.street.selected : this.formFMC.city.selected, this.formFMC.house.addressSearch)
        this.addressInfo.addressId = house.addressId
        this.addressInfo.addressStr = house.addressString
        this.addressInfo.addressGuid = house.FIASId
        this.addressInfo.isPrivateDistrict = true
        console.log(house)
        this.gotoNext()
      }
    },

    async createHouse(aoguid, housenum) {
      startLoading()
      const house = await api.fdemands.createHouse(aoguid, housenum)
          .then(checkForErrorByStatus)
          .then(r => r.data?.data)
          .catch(() => {
            showModalErrorDefault('Ошибка создания дома в АИСТ')
            return false
          })
          .finally(finishLoading)

      return house
    },

    showPreliminaryModal(msg, isUnite = false) {
      this.SET_PRELIMINARY({
        ...this.formFMC,
        ...{addressStr: this.addressWithApartment, addressGuid: this.addressGuid}
      })
      this.preliminaryMessage = msg
      this.modalErrorIsUnite = isUnite
      this.$bvModal.show('modalErrorFMC')
    },

    processPredefinedAddress() {
      console.log(this.addressInfo)
      if (!this.addressInfo) {
        return
      }

      if (this.addressInfo.regionId !== undefined) {
        this.formFMC.city.selectedText = this.addressInfo.regionText
        this.formFMC.city.addressSearch = this.addressInfo.regionText
        this.formFMC.city.selected = this.addressInfo.regionId
      }

      if (this.addressInfo.streetId !== undefined) {
        this.formFMC.street.selectedText = this.addressInfo.streetText
        this.formFMC.street.addressSearch = this.addressInfo.streetText
        this.formFMC.street.selected = this.addressInfo.streetId
      }
    },

    async selectExistingClient(guid) {
      const client = await this.getClient(guid)
      console.log('CLIENT IS ', client)
      if (!client) {
        //await showModalErrorDefault('Ошибка при выборе клиента')
        return
      }
      this.UPDATE_CLIENT_INFO({
        name: client.name ?? '',
        surname: client.lastname ?? '',
        middlename: client.middlename ?? '',
        account: client.accountnumber ?? null,
      })


      this.SET_FIXED(true)
      this.FMC_SET_EXISTING_CLIENT(guid)
      this.saveToStore()
      this.gotoNext()
    },
    selectNewClient() {
      this.FMC_SET_NEW_CLIENT()
      this.SET_FIXED(false)
      this.saveToStore()
      this.gotoNext()
    },
    onSelectNewClient() {
      if (this.clientsList.length > 0) {
        this.$bvModal.show('ModalClients')
        return
      }
      this.selectNewClient()
    },

    gotoNext() {
      this.GO_TO_PAGE_FMC(['requestFMCCreate', 'pageFormFMC2'])
    },

    extractAddressFromResponse(r) {
      console.log(r)
      this.addressStr = r.data.addressStr ?? ''
      this.addressGuid = r.data.fiasguid ?? ''
      this.addressId = r.data.addressId ?? null
    },

    async getClient(clientGuid) {
      return this.doGetClient(clientGuid)
          .then((r) => Object.values(r.data)[0])
          .catch(r => {
            showModalErrorDefault(r.message)
            return false
          })
    },

    extractConnectionInfo(data) {
      const base = {
        company_comment: "-",
        house_comment: "-",
        house_project: "-",
        economic_zone: "-",
        investment_category_name: "-",
        investment_category_id: null,
        access_time_from: null,
        access_time_to: null,
        home_restricted: [],
        connected: false,
        opportunity100: "Нет",
        opportunity100bool: false,
        technology: "-",
        technologyId: "",
        fvno_comment: "-",
        partnerId: '',
        fias: "",
        isPrivateDistrict: null
      }

      data = {...base, ...data}

      this.company_comment = data.company_comment
      this.home_comment = data.house_comment
      this.home_project = data.house_project
      this.economic_zone = data.economic_zone
      this.investment_category_name = data.investment_category_name
      this.investment_category_id = data.investment_category_id
      //this.access_time_from = data.accessTimeFrom
      //this.access_time_to = data.accessTimeTo
      this.home_restricted = data.houseRestricted
      this.hasConnections = data.connected
      if (data.opportunity100 != null) {
        this.opportunity100 = data.opportunity100 === 'true' ? 'Да' : 'Нет'
        this.opportunity100bool = data.opportunity100 === 'true'
      } else {
        this.opportunity100 = "Нет"
        this.opportunity100bool = false
      }
      this.technology = data.technology
      this.technologyId = data.technologyId
      this.fvno_comment = data.fvno_comment
      this.partnerId = data.partnerId
      this.fias = data.fiasguid
      this.isPrivateDistrict = data.isPrivateDistrict
      this.isPrivateDistrictVal = data.isPrivateDistrict
    },
    async checkAddress(data) {
      this.formFMC.house.selected = data.guid;
      this.formFMC.house.selectedText = data.value;

      let addressExist;
      addressExist = await this.doCheckAddress({
        cityId: this.formFMC.city.selected,
        streetId: this.formFMC.street.selected,
        addressId: this.formFMC.house.selected
      })

      this.isPrivateDistrict = addressExist.data.isPrivateDistrict;
      this.isPrivateDistrictVal = this.isPrivateDistrict
      this.hasConnections = addressExist.data.connected;
    },

    async submitFormFMCNext() {
      console.log(this.formFMC)
      let addressExist = false
      if (!this.predefinedAddress) {
        addressExist = await this.doCheckAddress({
          cityId: this.formFMC.city.selected,
          streetId: this.formFMC.street.selected,
          addressId: this.formFMC.house.selected,
          flat: this.formFMC.apartment,
        })
            .then((r) => {
              this.processConnected(r)
              return true
            })
            .catch((r) => {
              this.processNotConnected(r)
              return false
            })
      }

      if (addressExist || this.predefinedAddress) {
        await this.doFindClientByFias({
          fias: this.addressGuid,
          flat: this.formFMC.apartment
        })
            .then((r) => {
              this.incidentsList = r.incidents.map(({attributes}) => {
                return {
                  incidentId: attributes.incidentid,
                  accountNumber: attributes.gm_accountnumber,
                  sdNumber: attributes.sd_number,
                  fn: attributes.gm_surname,
                  name: attributes.gm_name,
                  ln: attributes.gm_middlename,
                  phone: attributes.gm_mobile,
                  address: attributes.gm_addressid.Name,
                  createdOn: attributes.createdon,
                  accountType: attributes.account_type,
                }
              })

              this.clientsList = r.data.map(({attributes}) => {
                return {
                  id: attributes.accountid,
                  fn: attributes.gm_lastname,
                  name: attributes.gm_firstname,
                  ln: attributes.gm_middlename,
                  phone: attributes.telephone1,
                  address: attributes.gm_addressid.Name,
                  accountNumber: attributes.accountnumber,
                  createdOn: attributes.createdon,
                  accountType: attributes.account_type,
                }
              })
            })
            .catch((r) => {
              this.clientsList = []
              this.incidentsList = []
              return Promise.resolve(r)
            })
            .then(() => {
              this.SHOW_CLIENTS_FMC()
            })
            .then(() => {
              //console.log(this.$refs.clientsList)
              //this.$refs.clientsList.scrollIntoView({behavior: 'smooth'})
              this.$smoothScroll({
                scrollTo: this.$refs.clientsList,
                offset: -70,
              })
            })


        if (this.clientsList.length < 1 && this.incidentsList < 1) {
          this.selectNewClient()
        }
      } else {
        this.HIDE_CLIENTS_FMC()
      }


      //console.log(addrInfo)
      //  if (this.hasConnections) {
      //     this.$store.commit( 'SHOW_CLIENTS_FMC' )
      //     this.GO_TO_PAGE_FMC(['pageFormFMC', 'pageFormFMC2'])
      //  } else {
      //      this.$bvModal.show('modalErrorFMC')
      //  }
      //     this.$v.$reset()
    },

    onChangeFormFMCAddress() {
      this.HIDE_CLIENTS_FMC()
      this.CHANGE_FORM_FMC()
      this.formFMCNext = {...this.$options.data.formFMCNext}
    },

    // Хак для отслеживания и сброса ввода несуществующего адреса
    applyOnBlurBehavior(inputRefs) {
      inputRefs.forEach((input) => {
        this.$refs[input + 'Input'].$el.querySelector('input').addEventListener('blur', (e) => {
          // в случае если blur происходит при переходе на селект с выбором адресов - ничего не делаем, так как это событие происходит раньше чем @hit
          if (e.relatedTarget?.classList?.contains('vbst-item')) {
            return true
          }
          this.formFMC[input].addressSearch = this.formFMC[input].selectedText
          return true
        })
      })
    },
  },
  mounted() {
    this.processPredefinedAddress()
  },
}
</script>

<style lang="scss">

.addr-hint {
  font-size: 11px;
  padding: 2px 0px;
}

.wrapper_addr_block {
  width: 100%;
}

.wrapper_addr_item {
  display: flex;
}

.wrapper_list {
  margin-left: 150px;
}

.request .tab-pane__input .wrapper_addr_item > label {
  width: 150px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.addr-item {
  width: 100%;
}

.addr-item .input-group input.form-control {
  width: 100%;
  max-width: 290px;
  height: 40px;
  line-height: normal;
  padding: 0 14px;
  border: 1px solid #b2b2b2;
  color: #333;
  border-radius: 0;

  &:hover, &:focus {
    border-color: #333;
    box-shadow: none;
  }
}
</style>

<style lang="scss" scoped>
.btn-slots {
  background: white;
  color: var(--mf-gray);
  border: 1px solid var(--mf-gray);
  margin-left: 20px;
}

.select {
  display: none;
  max-width: 290px;
}

.spinner__wrap {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button__wrap {
  flex: none;
  margin-top: 5px;

  a {
    border-bottom: 1px dashed #000080;
    height: 14px;
    cursor: pointer;
    color: #333333;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.list {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 200px;
  max-width: 500px;
  margin-top: 15px;

  li {
    margin: 15px 0;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  table {
    table-layout: fixed;

    th, td {
      padding: 0 15px 10px 0;
    }

    th {
      word-break: break-all;
      background-color: #ffffff;
      position: sticky;
      top: 0;
    }

    th:nth-child(1) {
      width: 100px
    }

    th:nth-child(2) {
      width: 70px
    }

    th:nth-child(3) {
      width: 75px
    }
  }
}

@media (max-width: 767px) {

  .request .tab-pane__input:not(.tab-pane__input_text) > label,
  .request .tab-pane__input.tab-pane__input_text.flex-wrap > label,
  .request .tab-pane__input .wrapper_addr_item > label {
    width: 100%;
    margin-bottom: 10px;
  }

  .tab-pane__input-radio {
    width: 100%;
    flex-wrap: wrap;
  }

  .input-radio {
    display: none;
  }

  .select {
    display: block;
  }

  .tab-pane__input.tab-pane__input_text br {
    display: block;
  }

  .spinner__wrap {
    padding: 10px 5px;
  }

  .wrapper_addr_item {
    flex-wrap: wrap;
  }

  .wrapper_list {
    margin-left: 0;
  }
}

</style>
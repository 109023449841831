<template>
   <div>
      <form @submit.prevent="">
         <div class="tab-pane__title">
            1/4. Поиск по ФИО и/или телефону
         </div>

         <div class="tab-pane__input">
            <label for="lName">Фамилия</label>
            <input id="lName"
                   type="text"
                   :disabled="btnSearch"
                   :value="searchValues.gm_lastname | stringFormat"
                   @input = "searchValues.gm_lastname = $event.target.value"
                   :class="{invalid: this.$v.searchValues.gm_lastname.$error}"
                   maxlength="50"
            >
         </div>

         <div class="tab-pane__input">
            <label for="name">Имя</label>
             <input id="name"
                    type="text"
                    :disabled="btnSearch"
                    :value="searchValues.gm_firstname | stringFormat"
                    @input = "searchValues.gm_firstname = $event.target.value"
                    :class="{invalid: this.$v.searchValues.gm_firstname.$error}"
                    maxlength="50"
             >
         </div>

         <div class="tab-pane__input">
            <label for="patronymic">Отчество</label>
             <input id="patronymic"
                    type="text"
                    :disabled="btnSearch"
                    :value="searchValues.gm_middlename | stringFormat"
                    @input = "searchValues.gm_middlename = $event.target.value"
                    maxlength="50"

             >
         </div>

          <div class="tab-pane__input">
              <label for="phone">Мобильный <br> телефон</label>
              <the-mask
                      id="phone"
                      mask="+7 ### ###-##-##"
                      v-model="searchValues.gm_phone"
                      :class="{invalid: this.$v.searchValues.gm_phone.$error}"
                      :disabled="btnSearch"
                      unmasked
              />
              <!--            <input id="phone"-->
              <!--                   type="text"-->
              <!--                   v-mask="'+7 ### ###-##-##'"-->
              <!--                   :disabled="btnSearch"-->
              <!--                   v-model="searchValues.gm_phone"-->
              <!--                   :class="{invalid: this.$v.searchValues.gm_phone.$error}"-->
              <!--            >-->
          </div>

         <div class="tab-pane__input">
            <label></label>
            <button v-if="!btnSearch"
                    @click="search"
                    class="btn">Поиск
            </button>
            <button v-else
                    @click="unlock"
                    class="btn">Изменить
            </button>
         </div>
      </form>
   </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import {requiredIf} from "vuelidate/lib/validators";

export default {
   name: "pageFormCRM_search",
   components: {},
   data() {
      return {
          btnSearch: false,
          searchValues: {
              gm_firstname: '',
              gm_middlename: '',
              gm_lastname: '',
              gm_phone: ''
          }
      }
   },
    computed: {
        ...mapGetters('CRM', [
            'searchLName',
            'searchName',
            'searchPatronymic',
            'searchPhone',
        ])
    },

    validations: {
        searchValues: {
            gm_firstname: {
                requiredIf: requiredIf((data) => !data.gm_phone)
            },
            // gm_middlename: {
            //     requiredIf: requiredIf((data) => !data.gm_phone)
            // },
            gm_lastname: {
                requiredIf: requiredIf((data) => !data.gm_phone)
            },
            gm_phone: {
                requiredIf: requiredIf((data) => !data.gm_firstname && !data.gm_middlename && !data.gm_lastname)
            },
        }
    },

    methods: {
        ...mapMutations('CRM', [
            'UPDATE_SEARCH_VALUE',
            'DISABLE_TABS_EXCEPT',
        ]),
        unlock() {
            this.btnSearch = false
            this.$emit('change')
        },
        search() {
            if (this.$v.searchValues.$invalid) {
                this.$v.searchValues.$touch()
                return
            }


            this.btnSearch = true
            this.DISABLE_TABS_EXCEPT('fio')

            this.$emit('search', this.searchValues)
        }
    },
    created() {
        //this.applyOnBlurBehavior(['city', 'street'])
        this.$on('reset', () => {
            Object.assign(this.$data, this.$options.data.apply(this))
        })
    }
}
</script>

<style lang="scss" scoped>

@media (max-width: 767px) {
   .request__clients .tab-pane__title {
      margin-bottom: 20px;
   }
}

</style>
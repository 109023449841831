<template>
  <b-modal :id="generateId('modalOrderInfo')"
           title="Информация по заявке"
           modal-class="request"
           centered
           no-fade
           no-stacking
           hide-footer
  >
    <div class="table-tariff">
      <div class="table-tariff__body-desc">
        <div class="table-tariff__row">
          <p>Статус</p>
          <p>{{ request.sd_substatusid }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Город</p>
          <p>{{ request.city }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Создал</p>
          <p>{{ request.gm_userloginnbnd }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Проект</p>
          <p>{{ request.project }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Дилер</p>
          <p>{{ request.gm_dealerorganizationname }}</p>
        </div>

        <div class="table-tariff__row">
          <p>ФИО клиента</p>
          <p>{{ request.gm_fio }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Мобильный телефон</p>
          <p>{{ request.gm_mobile }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Адрес подключения</p>
          <p>{{ request.gm_address }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Дата подключения</p>
          <p>{{ request.schedulestart }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Глобальный статус</p>
          <p>{{ request.statecode }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Дата закрытия</p>
          <p>{{ request.sd_closedate }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Отложено по</p>
          <p>{{ request.sd_suspendto }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Отложено по причине</p>
          <p>{{ request.gm_reasontohold_text }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Продукт (Списания ежемесячные/одноразовые)</p>
          <p v-html="request.gm_tariffs"></p>
        </div>

        <div class="table-tariff__row">
          <p>Скидка</p>
          <p>{{ request.discount_name }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Cумма ежемесячных списаний</p>
          <p v-html="request.gm_monthlypayment"></p>
        </div>

        <div class="table-tariff__row">
          <p>Мобильный телефон</p>
          <p>{{ request.gm_mobile }}</p>
        </div>
        <div class="table-tariff__row">
          <p>Дополнительный телефон</p>
          <p>{{ request.gm_homephone }}</p>
        </div>

        <div class="table-tariff__row">
          <p>Комментарий при создании (подробное описание)</p>
          <p>{{ request.gm_comment }}</p>
        </div>


        <div class="table-tariff__row">
          <div class="btn-group">
            <div v-if="!request.notcreated">
              <router-link target="_blank" :to="{...linkToViewDemand(request)}" class="btn">
                Подробнее
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import resizeWin from "@/mixins/resizeWin";
import {mapActions} from "vuex";
import urls from "@/configs/urls";
import multiModals from "@/mixins/multiModals";

export default {
  name: "ModalOrderInfo",
  props: {
    request: {
      type: Object,
      required: true,
    },
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  mixins: [resizeWin, multiModals],
  methods: {
    ...mapActions('CRM', ['doGetDemandInfo']),
    linkToViewDemand(item) {
      let url, guid, idParamName
      idParamName = 'guid'
      switch (item.project_id) {
        case 1:
          url = urls.XRM_VIEW_DEMAND_ROUTE
          guid = this.guid
          break
        case 2:
          url = urls.FMC_VIEW_DEMAND_ROUTE
          guid = this.guid
          break
        case 3:
          url = urls.UNITE_VIEW_DEMAND_ROUTE
          guid = item.gm_ccmpguid
          idParamName = 'id'
          break
      }

      return {
        name: url,
        params: {
          [idParamName]: guid
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-params-wrapp {
  max-width: 988px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 70px;

  @media (max-width: 1098px) {
    left: 0;
    max-width: none;
  }
  @media (max-width: 767px) {
    top: 50px;
  }
}

.btn-group {
  display: flex;
  justify-content: flex-start;

  div {
    margin-right: 20px;
  }
}

.btn-slots {
  background: white;
  color: var(--mf-gray);
  border: 1px solid var(--mf-gray);
}

.btn-params {
  width: 54px;
  height: 54px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -50px;
  margin-left: calc(50% - 27px);
  background: var(--mf-green) url('../../../assets/svg/filter_24.svg') center / 30px no-repeat;
  transition: .15s;
  opacity: 0;
}

.btn-params.show {
  opacity: 1;
  top: 30px;

}

.collapse-all-request {
  color: var(--mf-gray);
  font-size: 14px;
  line-height: 20px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.tab-pane__title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 29px;
}

.table-tariff {
  position: relative;

  .table-tariff__header {
    display: flex;
    color: var(--mf-gray);
    font-size: 14px;
    line-height: 20px;
    padding-left: 25px;
    margin-bottom: 20px;


  }

  .table-tariff__header,
  .table-tariff__body-head {
    p {
      padding-right: 20px;
      flex-shrink: 0;
    }

    p:first-child {
      width: 139px;
    }

    p:nth-child(2) {
      width: 117px;
    }

    p:nth-child(3) {
      width: 230px;
    }

    p:nth-child(4) {
      width: 131px;
    }

    p:nth-child(5) {
      width: 152px;
    }

    p:nth-child(6) {
      width: 192px;
    }

  }

  .table-tariff__body {
    border: 1px solid #D9D9D9;
    margin-top: -1px;
  }

  .table-tariff__body-head {
    display: flex;
    align-items: center;
    padding: 18px 0 18px 25px;
    position: relative;
    line-height: 20px;
  }

  .table-tariff__body-desc {
    background-color: #F7F7F7;
    border-top: 1px solid #D9D9D9;
    padding: 25px 25px 30px;
    line-height: 18px;
    word-break: break-word;

    .table-tariff__row {
      display: flex;
      margin-bottom: 23px;
    }

    p:first-child {
      color: var(--mf-gray);
      width: 196px;
      flex-shrink: 0;
      padding-right: 20px;
    }

    .btn {
      //margin-left: -8px;
    }

  }

  .table-tariff__delete {
    width: 30px !important;
    height: 30px;
    margin-right: 16px;
    cursor: pointer;
    background: url('../../../assets/svg/Delete_24.svg') center / 18px no-repeat;
  }

  .table-tariff__delete_empty {
    background: transparent;
    cursor: auto;
  }

  .table-tariff__arrow {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background: url('../../../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
    transform: rotate(90deg);
    position: absolute;
    right: 20px;
  }

  .table-tariff__body-head.not-collapsed {
    .table-tariff__arrow {
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 767px) {
    .table-tariff__header,
    .table-tariff__body-head {
      padding-left: 15px;
    }

    .table-tariff__body-desc {
      padding: 25px 15px 30px;
    }

    .table-tariff__header {
      margin-bottom: 12px;
    }

    .table-tariff__header p:first-child,
    .table-tariff__body-head p:first-child {
      width: 49%;
    }

    .table-tariff__body-desc p:first-child {
      width: 39%;
    }

    .table-tariff__body-desc .table-tariff__row {
      margin-bottom: 22px;
    }

    .table-tariff__arrow {
      right: 7px;
    }
  }
}
</style>
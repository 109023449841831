import axios from "@/axios";
import axiosCore from 'axios';
//import {checkForCancellation} from "@/utils/errors";
import jsonToFormData from 'json-form-data';

let citiesCancelTokenSource = axiosCore.CancelToken.source()
let streetsCancelTokenSource = axiosCore.CancelToken.source()
let housesCancelTokenSource = axiosCore.CancelToken.source()

export default {
    async findAddress(query) {
        citiesCancelTokenSource.cancel()
        citiesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findaddress', {
            cancelToken: citiesCancelTokenSource.token,
            params: {
                query
            }
        })
    },

    async checkConnection(guid, address) {
        return axios.get('/api/fajax/checkconnection', {
            params: {
                house_guid: guid,
                address: address,
            }
        })
    },


    async findCities(query) {
        citiesCancelTokenSource.cancel()
        citiesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findcities', {
            cancelToken: citiesCancelTokenSource.token,
            params: {
                query
            }
        })
    },

    async findStreets(query, cityID = null, all = false) {
        streetsCancelTokenSource.cancel()
        streetsCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findstreets', {
            cancelToken: streetsCancelTokenSource.token,
            params: {
                query,
                cityid: cityID,
                all: all,
            }
        })
    },

    async findHouses(query, cityID = null, streetID = null) {
        housesCancelTokenSource.cancel()
        housesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findhouses', {
            cancelToken: housesCancelTokenSource.token,
            params: {
                query,
                cityid: cityID,
                streetid: streetID,
            }
        })
    },

    async findAllHouses(cityID = null, streetID = null) {
        housesCancelTokenSource.cancel()
        housesCancelTokenSource = axiosCore.CancelToken.source()
        return axios.get('/api/fajax/findallhouses', {
            cancelToken: housesCancelTokenSource.token,
            params: {
                cityid: cityID,
                streetid: streetID,
            }
        })
    },

    async checkAddress(cityId, streetId, addressId, flat = null) {
        return axios.get('/api/fajax/checkaddress', {
            params: {
                cityid: cityId,
                streetid: streetId,
                addressid: addressId,
                flat
            }
        })
    },

    async createHouse(aoguid, housenum) {
      return axios.get('/api/fajax/createhouse', {
          params: {
              aoguid: aoguid,
              housenum: housenum,
          }
      })
    },

    async createPreliminary({fias, flat, firstname, lastname, patronymic, phone}) {
        const data = new FormData()
        data.append('pre-fias', fias)
        data.append('pre-flat', flat)
        data.append('pre-firstname', firstname)
        data.append('pre-lastname', lastname)
        data.append('pre-patronymic', patronymic)
        data.append('pre-mobile-phone', phone)
        return axios.post('/api/fajax/createpreliminary', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async findClientByFias(fias, flat) {
        return axios.get('/api/fajax/findclient', {
            params: {
                fiascode: fias,
                flat
            }
        })
    },

    async findClientByFiasAndName(fias, flat, name, middlename, surname, phone) {
        return axios.get('/api/fajax/findclient', {
            params: {
                fiascode: fias,
                gm_firstname: name,
                gm_middlename: middlename,
                gm_lastname: surname,
                gm_phone: phone,
                flat
            }
        })
    },

    async getClient(accountID) {
        return axios.get('/api/fajax/getclient', {
            params: {
                accountid: accountID,
            }
        })
    },

    async checkCustomer(phone, region, onlyCheck = false) {
        return axios.get('/api/fajax/checkcustomer', {
            params: {
                phone,
                region,
                onlyCheck
            }
        })
    },

    async checkTariffChange(regionId, currentTariff, newTariff) {
        return axios.get('/api/fajax/checktariffchange', {
            params: {
                regionId: regionId,
                currentTariff: currentTariff,
                newTariff: newTariff,
            }
        })
    },

    getProducts(gm_localityID, segment, accountID, subscriberId, current, familyAvailable = true, isPrivateDistrict) {
        return axios.get('/api/fajax/getproducts', {
            params: {
                gm_localityid: gm_localityID,
                account: accountID,
                segment: segment,
                subscriberid: subscriberId,
                current: current,
                familyAvailable: familyAvailable,
                isPrivateDistrict: isPrivateDistrict
            }
        })
    },

    async getServices(gm_localityID, accountID, category, productID, mainProductID, type, fias, segment, isMobile = false, familyAvailable = true, isPrivateDistrict = null) {
        return axios.get('/api/fajax/getservices', {
            params: {
                gm_localityid: gm_localityID,
                account: accountID,
                productId: productID,
                mainProductId: mainProductID,
                type: type,
                category: category,
                fias: fias,
                segment: segment,
                isMobile: isMobile,
                familyAvailable: familyAvailable,
                isPrivateDistrict: isPrivateDistrict
            }
        })
    },

    async getDiscounts(gm_localityID, segment, type = 'FMC', tariff = null, equipments = false, isPrivateDistrict = false) {
        return axios.get('/api/fajax/getdiscounts', {
            params: {
                gm_localityid: gm_localityID,
                segment,
                type,
                tariff,
                equipments,
                isPrivateDistrict
            }
        })
    },

    async createDemand(demandRequest) {
        //const data = new FormData()
        // Object.keys(demandRequest).forEach((key) => {
        //     data.append(key, demandRequest[key])
        // })

        const data = jsonToFormData(demandRequest, {
            initialFormData: new FormData(),
            includeNullValues: false,
        })

        return axios.post('/api/fajax/createdemand', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async getIncident(inquiryId) {
        return axios.get('/api/fajax/getincident', {
            params: {
                inquiryid: inquiryId
            }
        })
    },

    async slots(incidentGuid, fiasGuid) {
        return axios.get('/api/fajax/slots', {
            params: {
                gm_incidentid: incidentGuid,
                gm_fiasguid: fiasGuid,
            }
        })

    },

    async getAvailableSlots(incidentGuid, fiasGuid, connectionDate, workType) {
        return axios.get('/api/xajax/getavailableslots', {
            params: {
                gm_incidentid: incidentGuid,
                gm_fiasguid: fiasGuid,
                connection_date: connectionDate,
                worktype: workType,
            }
        })
    },

    async bookSlot(incidentGuid, localSlotId, worktype) {
        return axios.get('/api/xajax/bookslot', {
            params: {
                incident: incidentGuid,
                local_slot_id: localSlotId,
                worktype: worktype
            }
        })
    },

    async cancelSlot(incidentGuid, reasonCode) {
        return axios.get('/api/xajax/cancelslot', {
            params: {
                incident: incidentGuid,
                reasoncode: reasonCode,
            }
        })
    },

    async runAutoProcessing(incidentGuid) {
        return axios.get('/api/xajax/runautoprocessing', {
            params: {
                guid: incidentGuid,
            }
        })
    },

    async getSlotsHouseComments(fiasGuid) {
        return axios.get('/api/xajax/getslotshousecomments', {
            params: {
                gm_fiasguid: fiasGuid,
            }
        })
    },

    async getDemands(searchParams) {
        const data = new FormData()
        Object.entries(searchParams).forEach(([key, value]) => {
            if (Array.isArray(searchParams[key])) {
                value.forEach((arrItem) => {
                    data.append(key + '[]', arrItem)
                })
            } else {
                data.append(key, value)
            }

            console.log(data)
        })

        return axios.post('/api/fajax/getlistdemands', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async getDemandInfo(incident, project) {
        return axios.get('/api/fajax/getdemandinfo', {
            params: {
                incident: incident,
                project: project,
            }
        })
    },

    async getDetail(guid) {
        return axios.get('/api/fajax/getdetail', {
            params: {
                guid: guid,
            }
        })
    },

    async getCancelReasons(guid) {
        return axios.get('/api/xajax/getcancelreasons', {
            params: {
                guid
            }
        })
    },

    async commentorAttach(incident, gm_comment, file, document_type) {
        const fdata = new FormData();
        fdata.append('incident', incident)
        fdata.append('gm_comment', gm_comment)
        if (file) {
            fdata.append('file', file)
            fdata.append('document_type', document_type)
        }

        return axios.post('/api/fajax/commentorattach', fdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    async airInfo(suggestion) {
        const fdata = {
            data: suggestion,
        }
        return axios.post('/api/fajax/airinfo', fdata)
    }


}
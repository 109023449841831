<template>
   <div class="total-price-FMC">
       <p>Разовые доп. услуги (для активации): <span>{{ totalOneTimeService  | numFormat('0.00') }} ₽</span></p>
       <p>Сумма по тарифу: <span>{{ totalTariff  | numFormat('0.00') }} ₽/мес</span></p>
       <p>Сумма доп. услуг: <span>{{ totalService  | numFormat('0.00') }} ₽/мес</span></p>
       <p>Абонентская плата: <span>{{ totalOver  | numFormat('0.00') }} ₽/мес</span></p>
       <p v-if="totalWithSale">Абонентская плата со скидкой: <span>{{ totalWithSale.monthly  | numFormat('0.00') }} ₽/мес</span></p>
       <p v-if="totalWithSale">Сумма активации со скидкой: <span>{{ totalWithSale.activation  | numFormat('0.00') }} ₽</span></p>
       <p v-else>Сумма активации: <span>{{ totalActivation  | numFormat('0.00') }} ₽</span></p>
       <p v-if="addressInfo.isPrivateDistrict">Есть плата за подключение, ее размер сообщит оператор</p>
   </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
   name: "TotalPriceFMC",

   computed: mapGetters([
       'totalPriceFMCTotal',
       'totalPriceFMCAbon',
       'totalActivation',
       'totalService',
       'totalOneTimeService',
       'totalTariff',
       'totalOver',
       'totalWithSale',
       'pageFormFMC6',
       'addressInfo'
   ])
}
</script>

<style lang="scss" scoped>
.total-price-FMC {
   text-align: right;
   margin-bottom: 30px;

   p {
      font-size: 19px;
      line-height: 25px;
   }

   span {
      font-weight: 500;
   }

   @media (max-width: 767px) {
      p {
         font-size: 16px;
      }
   }
}
</style>
<template>
   <div class="request__data-clients">

      <div class="tab-pane__title">
          {{ isExistingClient ? '2' : '3' }}/4. Данные клиента
      </div>

      <form @submit.prevent="submit">
          <template v-if="!isExistingClient">
              <div class="tab-pane__input" v-if="!isExistingClient">
                  <label class="star">Фамилия</label>
                  <input type="text"
                         :disabled="isExistingClient || disabledInput"
                         :value="clientInfo.lastname | stringFormat"
                         @input = "clientInfo.lastname = $event.target.value"
                         :class="{ invalid: validFamily }"
                         maxlength="50"
                         v-if="!isExistingClient"
                  >
                  <input type="text"
                         :disabled="isExistingClient || disabledInput"
                         :value="clientInfo.lastname | stringFormat"
                         @input = "clientInfo.lastname = $event.target.value"
                         :class="{ invalid: validFamily }"
                         maxlength="50"
                         v-else
                  >
              </div>

              <div class="tab-pane__input" v-if="!isExistingClient">
                  <label class="star">Имя</label>
                  <input type="text"
                         :disabled="isExistingClient || disabledInput"
                         :value="clientInfo.name | stringFormat"
                         @input = "clientInfo.name = $event.target.value"
                         :class="{ invalid: validName }"
                         maxlength="50"
                         v-if="!isExistingClient"
                  >
                  <input type="text"
                         :disabled="isExistingClient || disabledInput"
                         :value="clientInfo.name | stringFormat"
                         @input = "clientInfo.name = $event.target.value"
                         :class="{ invalid: validName }"
                         maxlength="50"
                         v-else
                  >
              </div>

              <div class="tab-pane__input" v-if="!isExistingClient">
                  <label>Отчество</label>
                  <input type="text"
                         :disabled="disabledInput"
                         :value="clientInfo.middlename | stringFormat"
                         @input = "clientInfo.middlename = $event.target.value"
                         maxlength="50"
                  >
              </div>

              <div class="tab-pane__input mob-phone" v-if="!isExistingClient">
                  <label class="star">Мобильный <br> телефон</label>
                  <input type="tel"
                         :disabled="disabledInput"
                         v-mask="'+7 ### ###-##-##'"
                         v-model.trim="clientInfo.phone"
                         :class="{ invalid: validMobPhone }"
                  >
              </div>

         <div class="tab-pane__input">
            <label>Домашний <br> телефон</label>
            <input type="tel"
                   :disabled="disabledInput"
                   v-mask="'+7 ### ###-##-##'"
                   v-model.trim="clientInfo.homePhone"
                   :class="{ invalid: validHomePhone }"
            >
         </div>

         <div class="tab-pane__input">
            <label>E-mail</label>
            <input type="text"
                   :disabled="disabledInput"
                   v-model="clientInfo.email"
                   :class="{invalid: this.$v.clientInfo.email.$error}"
            >
         </div>

              <div class="tab-pane__input">
                  <label>UTM-метка</label>
                  <input type="text"
                         :disabled="disabledInput"
                         v-model="clientInfo.utm"
                  >
              </div>
          </template>

          <template v-if="isExistingClient">
              <div class="tab-pane__input" v-if="isExistingClient">
                  <label>ФИО</label>
                  <div>{{ clientInfo.name }} {{ clientInfo.middlename }} {{ clientInfo.lastname }}</div>
              </div>

              <div class="tab-pane__input" v-if="isExistingClient">
                  <label>Адрес</label>
                  <div>{{ addressInfo.addressText }}</div>
              </div>

              <div class="tab-pane__input" v-if="isExistingClient">
                  <label>Частный сектор</label>

                  <div class="tab-pane__input-radio">

                      <!--                      <div class="input-radio">-->
                      <!--                          <input id="no"-->
                      <!--                                 type="radio"-->
                      <!--                                 name="sector"-->
                      <!--                                 :value="false"-->
                      <!--                                 v-model="addressInfo.is_private"-->
                      <!--                          >-->
                      <!--                          <label for="no">Нет-->
                      <!--                              <label></label>-->
                      <!--                          </label>-->
                      <!--                      </div>-->

                      <!--                      <div class="input-radio">-->
                      <!--                          <input id="yes"-->
                      <!--                                 type="radio"-->
                      <!--                                 name="sector"-->
                      <!--                                 :value="true"-->
                      <!--                                 v-model="addressInfo.is_private"-->
                      <!--                          >-->
                      <!--                          <label for="yes">Да-->
                      <!--                              <label></label>-->
                      <!--                          </label>-->
                      <!--                      </div>-->

                      <b-form-select :options="selectOptions" v-model="addressInfo.is_private"
                                     :disabled="addressInfo.is_private_orig"></b-form-select>
                  </div>
              </div>

              <div class="tab-pane__input" v-if="isExistingClient">
                  <label>Подключенные услуги</label>
                  <div class="services">
                      <p :key="index" v-for="(service, index) in includedServices"><b>{{ service.product }}</b>
                          {{ service.tariff }} </p>
                  </div>
              </div>
          </template>

          <div class="tab-pane__input">
              <label>Номер для SMS <br> информирования</label>
              <input type="text"
                     v-mask="'+7 9## ###-##-##'"
                     v-model="clientInfo.smsInformingNumber"
                     :disabled="disabledInput"
                     :class="{invalid: validSmsInformingNumber}"
              >
          </div>

          <div class="tab-pane__input">
            <label>Комментарий <br> к заявке</label>
            <input type="text"
                   :disabled="disabledInput"
                   v-model="clientInfo.comment"
            >
         </div>

          <ThreeBtns :showBtnChange="disabledInput"
                     :back="prevStepParams"
                     @submit="submit"
                     @change="change"
          />
      </form>
   </div>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators";
import {mapGetters, mapMutations} from "vuex";
import ThreeBtns from "@/components/ThreeBtns";

export default {
    name: "pageFormCRM3",
    components: {
        ThreeBtns
    },
    data() {
        return {
            showBtnChange: false,
            selectOptions: [{text: 'Да', value: true}, {text: 'Нет', value: false}],
        }
    },
    watch: {
        'clientInfo.phone': function (newVal) {
            if (this.checkSmsInformingNum(this.clientInfo.smsInformingNumber))
                return
            if (this.checkSmsInformingNum(newVal)) {
                this.clientInfo.smsInformingNumber = newVal
            } else {
                this.clientInfo.smsInformingNumber = ''
            }
        }
    },
    computed: {
        ...mapGetters('CRM', [
            'disabledInput',
            'formCRM2',
            'addressInfo',
            'clientInfo',
            'isExistingClient',
            'includedServices',
        ]),
        validFamily() {
           return !this.$v.clientInfo.lastname.required && this.$v.clientInfo.lastname.$dirty
       },
       validName() {
           return !this.$v.clientInfo.name.required && this.$v.clientInfo.name.$dirty
       },
       validMobPhone() {
           return (!this.$v.clientInfo.phone.required || !this.$v.clientInfo.phone.minLength)
               && this.$v.clientInfo.phone.$dirty
       },
      validHomePhone() {
        return (!this.$v.clientInfo.homePhone.minLength)
            && this.$v.clientInfo.homePhone.$dirty
      },
       validSmsInformingNumber() {
           return this.$v.clientInfo.smsInformingNumber.$error
       },

       prevStepParams() {
           if (!this.isExistingClient) {
               return ['CRM', 'pageFormCRM3', 'pageFormCRM2']
           } else {
               return ['CRM', 'pageFormCRM3', 'pageFormCRM']
           }
       },
   },
   validations: {
       clientInfo: {
           lastname: {required},
           name: {required},
           phone: {required, minLength: minLength(16)},
           homePhone: {minLength: minLength(16)},
           email: {
               email: email,
           },
           smsInformingNumber: {
               //requiredIf: requiredIf((data) => data.smsInformingNumber !== ''),
               minLength: (value) => value ? minLength(16)(value) : true,
               mask: function (value) {
                   return value ? this.checkSmsInformingNum(value) : true
               }
           },
       },

       nonExistingClientGroup: ['clientInfo'],
       existingClientGroup: ['clientInfo.smsInformingNumber']

   },
   methods: {
       ...mapMutations('CRM', [
           'GO_TO_PAGE_CRM',
           'BTN_FORM2_DISABLED',
           'BTN_FORM2_TOGGLE'
       ]),
       checkSmsInformingNum(num) {
           return (/\+7 9[0-9\s-]{12}/).test(num)
       },
       change() {
           if (!this.isExistingClient && this.$v.nonExistingClientGroup.$invalid) {
               this.$v.clientInfo.$touch()
           } else if (this.isExistingClient && this.$v.existingClientGroup.$invalid) {
               this.$v.clientInfo.$touch()
           } else {
               this.BTN_FORM2_TOGGLE()
           }
       },
       submit() {
          if (!this.isExistingClient && this.$v.nonExistingClientGroup.$invalid) {
              this.$v.clientInfo.$touch()
          } else if (this.isExistingClient && this.$v.existingClientGroup.$invalid) {
              this.$v.clientInfo.$touch()
          } else {
              this.$store.commit('CRM/BACK')
              //this.$store.commit('CRM/GO_TO_PAGE_CRM', ['pageFormCRM3', 'pageFormCRM4'])
              this.nextStep()
              this.$v.$reset()
              this.showBtnChange = true
          }
      },

       nextStep() {
           if (!this.isExistingClient) {
               this.$store.commit('CRM/GO_TO_PAGE_CRM', ['pageFormCRM3', 'pageFormCRM4'])
           } else {
               this.$store.commit('CRM/GO_TO_PAGE_CRM', ['pageFormCRM3', 'pageFormCRM2'])
           }
       }

   }
}
</script>


<style lang="scss" scoped>

.select {
  width: 100px;
}

.services p {
  padding: 5px 0;
}

@media (max-width: 767px) {
  .tab-pane__input label {
    width: 100% !important;

    br {
      display: none;
    }
  }

  .select {
    width: 100%;
  }
}

</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMask from 'vue-the-mask'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import numFormat from 'vue-filter-number-format'
import {sync} from 'vuex-router-sync';
import Vue2SmoothScroll from "vue2-smooth-scroll";
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import vSelect from "@/components/vSelect"
import 'selectize/dist/css/selectize.default.css'
import VSelectize from '@isneezy/vue-selectize'
import VueYandexMetrika from 'vue-yandex-metrika'
import './registerServiceWorker'

Vue.component('v-selectize', VSelectize)
Vue.component('v-select', vSelect)
//Vue.use(VueAxios, axios)
Vue.filter('numFormat', numFormat)
Vue.use(VueFilterDateFormat)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMask)

Vue.filter('stringFormat', function (value) {
    if (value) {
        return value.replace(/[^a-zа-яё/-]/gi, "")
    }
})

Vue.use(VueYandexMetrika, {
    id: process.env.NODE_ENV === 'production' ? 61141078 : 61141066,
    router: router,
    //env: process.env.NODE_ENV,
    env: 'production', // always track
    debug: true,
    // other options
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    }
})
Vue.use(Vue2SmoothScroll)

Vue.config.productionTip = false

sync(store, router)

export default store.dispatch('user/loadUserSession', null, {root: true})
    .then(() => {
        const inst = new Vue({
            router,
            store,
        render: h => h(App),
      })
      inst.$mount('#app')
      return inst
    })

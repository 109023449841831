<template>
    <div class="page-form-FMC4">

        <div class="tab-pane__title">
            4/8. Доп. услуги и оборудование
        </div>

        <div class="request__tabs">
            <b-tabs>
                <b-tab title='Мобил связь'>
                    <RentFMC module="mobile" service-type="mobile" :countable="false"/>
                </b-tab>
                <b-tab title='Оборудование' active>
                    <RentFMC module="installment" service-type="installment" :countable="false"/>
                </b-tab>
            </b-tabs>
        </div>

        <TotalPriceFMC/>

        <div class="btns">
            <div class="btn back"
                 @click="prevStep">
                Назад
            </div>
            <div class="btn"
                 @click="GO_TO_PAGE_FMC(['pageFormFMC4','pageFormFMC5'])">
                Подтвердить
            </div>
        </div>
    </div>
</template>

<script>
import RentFMC from "@/components/requestOEveryWhere/RentFMC";
//import FixFMC from "@/components/requestFMC/FixFMC";
import TotalPriceFMC from "@/components/requestOEveryWhere/TotalPriceFMC";
import {mapMutations} from "vuex";

export default {
    name: "pageFormFMC4",
    components: {
        RentFMC,
        //FixFMC,
        TotalPriceFMC
    },
    methods: {
        ...mapMutations('OEveryWhere', [
            'GO_TO_PAGE_FMC'
        ]),
        ...mapMutations('OEveryWhere/rent', {ROLLBACK_RENT: 'ROLLBACK', CLEANUP_BASKET_RENT: 'CLEANUP_BASKET'}),
        ...mapMutations('OEveryWhere/fix', {ROLLBACK_FIX: 'ROLLBACK', CLEANUP_BASKET_FIX: 'CLEANUP_BASKET'}),

        prevStep() {
            //this.ROLLBACK_FIX()
            //this.ROLLBACK_RENT()
            this.GO_TO_PAGE_FMC(['pageFormFMC4', 'pageFormFMC3'])
        }
    }
}
</script>

<style lang="scss">
.page-form-FMC4 {
  .request__tabs {
    margin-bottom: 28px;
  }

  .selectize-control.single {
    max-width: 65px;

    .selectize-input:after {
      right: 8px !important;
    }
  }
}
</style>
<template>
   <div class="request__data-clients">

      <div class="tab-pane__title">
         6/7. Данные клиента
      </div>

      <form @submit.prevent="submit">

         <div class="tab-pane__input">
             <label class="star" for="family">Фамилия</label>
             <input v-if="predisabledFields.fio" id="family"
                    type="text"
                    :disabled="true"
                    :value="maskSurname"
             >
             <input v-else id="family"
                    type="text"
                    :disabled="predisabledFields.fio || formDisabledFMC2"
                    :value="clientInfo.surname | stringFormat"
                    @input = "clientInfo.surname = $event.target.value"
                    :class="{ invalid: validFamily }"
                    maxlength="50"
             >
         </div>

          <div class="tab-pane__input">
              <label class="star" for="name">Имя</label>
              <input id="name"
                     type="text"
                     :disabled="predisabledFields.fio || formDisabledFMC2"
                     :value="clientInfo.name | stringFormat"
                     @input = "clientInfo.name = $event.target.value"
                     :class="{ invalid: validName }"
                     maxlength="50"
              >
          </div>

          <div class="tab-pane__input">
              <label for="patronym">Отчество</label>
            <input v-if="predisabledFields.fio" id="patronym"
                   type="text"
                   :value="maskMiddlename"
                   :disabled="true"
            >
              <input v-else id="patronym"
                     type="text"
                     :value="clientInfo.middlename | stringFormat"
                     @input = "clientInfo.middlename = $event.target.value"
                     :disabled="predisabledFields.fio || formDisabledFMC2"
                     maxlength="50"
              >
          </div>

          <div class="tab-pane__input mob-phone">
              <label class="star" for="mob-phone">Контактный <br> телефон</label>
              <input v-if="predisabledFields.fio && segmentID === 2" id="mob-phone"
                     type="tel"
                     v-mask="'+7 ### ***-##-##'"
                     :value="maskPhone"
                     :disabled="true"
              >
              <input v-else id="mob-phone"
                     type="tel"
                     :disabled="formDisabledFMC2"
                     v-mask="'+7 ### ###-##-##'"
                     v-model.trim="clientInfo.mobPhone"
                     :class="{ invalid: validMobPhone }"
              >
         </div>
          <div class="tab-pane__input mob-phone">
              <label for="phone">Дополнительный<br>телефон</label>
              <input id="phone"
                    type="tel"
                    :disabled="formDisabledFMC2"
                    v-mask="'+7 ### ###-##-##'"
                    v-model.trim="clientInfo.homePhone"
                    :class="{ invalid: validMobPhone }"
              >
          </div>
          <div class="tab-pane__input" v-if="addressWithApartmentAsText">
            <label>Адрес</label>
            <div>{{ addressWithApartmentAsText }}</div>
          </div>
          <div class="tab-pane__input">
            <label v-if="addressInfo.isPrivateDistrict" for="front">Подъезд</label>
            <label v-else class="star"  for="front">Подъезд</label>
            <input id="front"
                   type="number"
                   :disabled="formDisabledFMC2"
                   v-model.trim="addressInfo.porch"
                   :class="{ invalid: addressInfo.isPrivateDistrict ? '' : validPorch}"
            >
          </div>
          <div class="tab-pane__input">
            <label for="level">Этаж</label>
            <input id="level"
                   type="number"
                   :disabled="formDisabledFMC2"
                   v-model.trim="addressInfo.floor"
                   :class="{ invalid: validFloor}"
            >
          </div>
          <!--         <div class="tab-pane__input">-->
          <!--            <label for="phone">Домашний <br> телефон</label>-->
          <!--            <input id="phone"-->
          <!--                   type="tel"-->
          <!--                   :disabled="formDisabledFMC2"-->
          <!--                   v-mask="'##########'"-->
          <!--                   v-model.trim="clientInfo.homePhone"-->
          <!--            >-->
          <!--         </div>-->

          <!--         <div class="tab-pane__input">-->
          <!--            <label for="mail">E-mail</label>-->
          <!--            <input id="mail"-->
          <!--                   type="text"-->
          <!--                   :disabled="formDisabledFMC2"-->
          <!--                   v-model.trim="clientInfo.email"-->
          <!--            >-->
          <!--         </div>-->

          <!--         <div class="tab-pane__input">-->
          <!--            <label for="UTM">UTM-метка</label>-->
          <!--            <input id="UTM"-->
          <!--                   type="text"-->
          <!--                   :disabled="formDisabledFMC2"-->
          <!--            >-->
          <!--         </div>-->

          <!--         <div class="tab-pane__input">-->
          <!--            <label for="sms">Номер для SMS <br> информирования</label>-->
          <!--            <input id="sms"-->
          <!--                   type="text"-->
          <!--                   v-mask="'+7 ### ###-##-##'"-->
          <!--                   :disabled="formDisabledFMC2"-->
          <!--                   v-model="clientInfo.smsInformingNumber"-->
          <!--            >-->
          <!--         </div>-->

          <div class="tab-pane__input">
              <label for="comment">Комментарий<br/> при создании</label>
              <textarea id="comment"
                     maxlength="249"
                     rows="3"
                     type=""
                     :disabled="formDisabledFMC2"
                     v-model="clientInfo.comment"
              ></textarea>
          </div>

          <ThreeBtns :showBtnChange="showBtnChange"
                     :back="['FMC', 'pageFormFMC6', 'pageFormFMC5']"
                     @submit="submit"
                     @change="change"
          />
      </form>
   </div>
</template>

<script>
import {required, minLength, minValue, integer} from "vuelidate/lib/validators";
import {mapGetters, mapMutations} from "vuex";
import ThreeBtns from "@/components/ThreeBtns";

export default {
   name: "pageFormFMC6",
   components: {
      ThreeBtns
   },
   data() {
      return {
         showBtnChange: false,
      }
   },
   computed: {
       ...mapGetters([
           'formDisabledFMC2',
           'formFMC2',
       ]),
       ...mapGetters('clientInfo', [
           'clientInfo',
           'maskSurname',
           'maskMiddlename',
           'maskPhone'
       ]),
       ...mapGetters('segment', [
         'segmentID',
       ]),
       ...mapGetters([
            'addressInfo',
            'addressWithApartmentAsText'
       ]),
       validFamily() {
         console.log("validFamily");
           return !this.$v.clientInfo.surname.required && this.$v.clientInfo.surname.$dirty
       },
       validName() {
           return !this.$v.clientInfo.name.required && this.$v.clientInfo.name.$dirty
       },
       validMobPhone() {
           return (!this.$v.clientInfo.mobPhone.required || !this.$v.clientInfo.mobPhone.minLength)
               && this.$v.clientInfo.mobPhone.$dirty
       },
       validHomePhone() {
         return (!this.$v.clientInfo.homePhone.minLength)
             && this.$v.clientInfo.homePhone.$dirty
       },
       validPorch() {
         return (!this.$v.addressInfo.porch.required || !this.$v.addressInfo.porch.integer || !this.$v.addressInfo.porch.minValue) && this.$v.addressInfo.porch.$dirty
       },
       validFloor() {
         if (this.addressInfo.floor === '')
           return false
         return ((!RegExp('[0-9]').test(this.addressInfo.floor) || !this.$v.addressInfo.floor.minValue)) && this.$v.addressInfo.floor.$dirty
       },
       predisabledFields() {
           return {
               fio: this.$store.getters["segment/segmentID"] !== 0,
           }
       },
   },

   validations: {
       clientInfo: {
           surname: {required},
           name: {required},
           mobPhone: {required, minLength: minLength(16)},
           homePhone: {minLength: minLength(16)},
       },
       addressInfo: {
           porch: {integer},
           floor: {integer, minValue: minValue(-999)},
       }
   },

   methods: {
       ...mapMutations([
           'GO_TO_PAGE_FMC',
           'DISABLED_FORM_FMC2',
           'DISABLED_FORM_CHANGE_FMC2'
       ]),

       ...mapMutations('clientInfo', [
           "UPDATE_CLIENT_INFO"
       ]),

       getUpdaterForField(fieldName) {
           return ({target}) => {
               this.UPDATE_CLIENT_INFO({
                   [fieldName]: target.value,
               })
           }
       },

       change() {
           if (this.$v.clientInfo.$invalid) {
               this.$v.clientInfo.$touch()
           } else if (this.$v.addressInfo.$invalid) {
               this.$v.addressInfo.$touch()
           } else {
               this.DISABLED_FORM_CHANGE_FMC2()
           }
       },
       submit() {
           if (this.$v.clientInfo.$invalid) {
               this.$v.clientInfo.$touch()
           } else if (this.$v.addressInfo.$invalid) {
             this.$v.addressInfo.$touch()
           } else {
               this.DISABLED_FORM_FMC2()
               this.GO_TO_PAGE_FMC(['pageFormFMC6', 'pageFormFMC7'])
               this.$v.$reset()
               this.showBtnChange = true
           }

       }
   }
}
</script>

<style lang="scss" scoped>

.tab-pane__input {
    textarea {
        max-width: 290px;
        width: 100%;
    }
}

@media (max-width: 767px) {
   .tab-pane__input {

      label {
         width: 100% !important;

         br {
            display: none;
         }
      }

   }
}

</style>
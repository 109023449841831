<template>
  <div class="content orders">
    <div class="wrapper">
      <div class="wrapper__block">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <p class="big-green-title">{{breadcrumb[breadcrumb.length -1].text}}</p>
        <b-tabs @activate-tab="handleTabChange" v-model="selectTab">
          <b-tab title="Закрытые заявки">
            <div class="table-orders">
              <div>
                <div v-for="(item, index) of closedOrders"
                     :key="index"
                >
                  <OrderList :order="item" :index="index" :collapseAll="collapseAll"/>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Активные заявки">
            <div class="table-orders">
              <div>
                <div v-for="(item, index) of activeOrders"
                     :key="index"
                >
                  <OrderList :order="item" :index="index" :collapseAll="collapseAll"/>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import resizeWin from "@/mixins/resizeWin";
import OrderList from "@/views/OrderList.vue";

const TAB_MY_CLOSED_ORDERS = 0
const TAB_MY_ACTIVE_ORDERS = 1

export default {
  name: "orders",
  components: {OrderList},

  data() {
    return {
      closedOrders: [],
      activeOrders: [],
      collapseAll: false,
      btnParams: false,
      breadcrumb: [
        {
          text: 'Главная страница',
          to: {name: 'home'}
        }, {
          text: 'Мои заявки',
          active: true
        }
      ],
    }
  },
  mixins: [resizeWin],
  computed:{
    selectTab: {
      get() {
        return TAB_MY_ACTIVE_ORDERS
      },
      set(val) {
        this.tabNum = val
      }
    }
  },
  methods: {
    ...mapActions('user', ['doGetClosedOrders', 'doGetActiveOrders', 'doGetOrdersList']),
    handleTabChange(tabIndex) {
      this.checkedNotifications = []
      switch (tabIndex) {
        case TAB_MY_CLOSED_ORDERS:
          this.loadClosedOrders()
          break
        case TAB_MY_ACTIVE_ORDERS:
          this.loadActiveOrders()
          break
      }
    },
    async loadClosedOrders() {
      this.closedOrders = await this.doGetClosedOrders()
          .catch(() => [])
      console.log(this.closedOrders)
    },
    async loadActiveOrders() {
      this.activeOrders = await this.doGetActiveOrders()
          .catch(() => [])
      console.log(this.activeOrders)
    },
    async showDesc(status) {
      console.log(status)

      const items = await this.doGetOrdersList({
        status: status
      })
          .catch(() => [])
      console.log(items)
    },
  },
  async mounted() {
    await  this.loadActiveOrders()
  }
}
</script>

<style lang="scss">
.orders {
  padding: 18px 0 37px;

  .btn-params-wrapp {
    max-width: 988px;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 70px;

    @media (max-width: 1098px) {
      left: 0;
      max-width: none;
    }
    @media (max-width: 767px) {
      top: 50px;
    }
  }

  .btn-params {
    width: 54px;
    height: 54px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: -50px;
    margin-left: calc(50% - 27px);
    background: var(--mf-green) url('../assets/svg/filter_24.svg') center / 30px no-repeat;
    transition: .15s;
    opacity: 0;
  }

  .btn-params.show {
    opacity: 1;
    top: 30px;
  }

  .breadcrumb {
    margin-bottom: 34px;
  }

  .tab-content {
    padding-top: 30px;
  }

  .big-green-title {
    margin-bottom: 34px;
  }

  .buttons {
    text-align: left;
    flex-grow: 1;

    .btn {
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 0;
    }
  }

  .notifications__block {
    margin-bottom: 0;

    li {
      display:  flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 27px;
      line-height: 20px;
      padding-left: 30px;


      &:last-child {
        margin-bottom: 0;
      }
    }

    li.new {
      font-weight: bold;
    }

    li.new input[type=checkbox]{
      height: auto;
      position: absolute;
      left: 0;
      top: 2px;
    }

    li:not(.new):before {
      content: '— ';
      position: absolute;
      left: 0;
    }

    a {
      color: var(--mf-blue);
    }
  }

  .table-orders {
    position: relative;

    a {
      color: var(--mf-blue);
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 65px;

    .breadcrumb {
      margin-bottom: 17px;
    }

    .tab-content {
      padding-top: 30px;
    }

    .big-green-title {
      margin-bottom: 27px;
    }

    .wrapper {
      padding: 0;
    }

    .wrapper__block {
      padding: 20px 15px 35px;
    }
  }
}
</style>
<template>
  <div class="table-orders__body">
    <div class="table-orders__body-head"
         v-b-toggle:v-bind="'collapse-request-' + index"
    >
      <div>
        <p>{{ order.name }}</p>
        <p class="table-orders__body-head-period" v-if="order.period"> {{ period }}</p>
      </div>
      <p>{{ order.count }}</p>
      <p class="sphere" :style="{'background-color': order.color}"></p>
      <div class="table-orders__arrow"></div>
    </div>
    <b-collapse :id="'collapse-request-' + index"
                v-model="order.collapse" v-on:shown="showList(order.status, order.period)"
    >
      <div class="table-orders__body-desc">
        <div class="table-orders__header">
          <p :style="{display: hide(342)}"></p>
          <p>Дата создания</p>
          <p>Номер</p>
          <p :style="{display: hide(767)}">Статус</p>
          <p :style="{display: hide(810)}">Регион</p>
          <p :style="{display: hide(965)}">Создал</p>
          <p :style="{display: hide(1110)}">Дата и время подключения</p>
          <p :style="{display: hide(1110)}">Отложено по</p>
        </div>
        <div v-for="(request, index) of orders"
             :key="index">
          <OrderInfoElement :dataRequest="request" :modal-id="request.incidentId"/>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
    import {mapActions} from "vuex";
    import resizeWin from "@/mixins/resizeWin";
    import multiModals from "@/mixins/multiModals";
    import OrderInfoElement from "@/views/OrderInfoElement.vue";

    export default {
        name: "OrderList",
        components: {OrderInfoElement},
        props: {
            index: {
              required: true,
            },
            order: {
                type: Object,
                required: true,
            },
            collapseAll: {
                type: Boolean,
                default: false,
                required: false,
            }
        },
        watch: {
            collapseAll(newVal) {
                this.collapseElement = newVal
            }
        },
        data() {
            return {
                collapseElement: false,
                orders: [],
            }
        },
        mixins: [resizeWin, multiModals],
        computed: {
          period() {
            return (this.order.period === "lastMonth") ? "в прошлом месяце" : "в текущем месяце"
          }
        },
        methods: {
          ...mapActions('user', ['doGetOrdersList']),
          request(item) {
            return item
          },
          async showList(status, period) {
            this.orders = await this.doGetOrdersList({
              status: status,
              period: period
            })
                .catch(() => [])

            this.order.count = this.orders.length
            this.collapseElement = true
          },
        },
    }
</script>

<style lang="scss" scoped>
  a {
    color: var(--mf-blue);
  }

  .table-orders__body {
    border: 1px solid #D9D9D9;
    margin-top: -1px;
  }

  .table-orders__header {
    display: flex;
    color: var(--mf-gray);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;

    p {
      padding-right: 20px;
      flex-shrink: 0;
    }
  }

  .table-orders__row {
    p {
      display: flex;
      align-items: center;
      padding-right: 20px;
      flex-shrink: 0;
    }
  }

  .table-orders__header,
  .table-orders__row  {
    p:first-child {
      width: 40px;
    }

    p:nth-child(2) {
      width: 140px;
    }

    p:nth-child(3) {
      width: 110px;
    }

    p:nth-child(4) {
      width: 150px;
    }

    p:nth-child(5) {
      width: 140px;
    }

    p:nth-child(6) {
      width: 84px;
    }

    p:nth-child(7) {
      width: 110px;
    }

    p:nth-child(8) {
      width: 110px;
    }
  }

  .table-orders__body-head {
    display: flex;
    align-items: normal;
    padding: 18px 30px 18px 25px;
    position: relative;
    line-height: 20px;

    .table-orders__body-head-period {
      padding-left: 20px;
    }
  }

  .table-orders__body-head.new p{
    font-weight: bold;
  }

  .table-orders__body-head {
    p {
      padding-right: 20px;
      flex-shrink: 0;
    }

    p:first-child {
    }

    p:nth-child(2) {
      margin-left: auto;
    }
  }

  .table-orders__body-desc {
    position: relative;
    background-color: #F7F7F7;
    border-top: 1px solid #D9D9D9;
    padding: 25px 25px 30px 70px;
    line-height: 18px;
    word-break: break-word;

    .table-orders__row {
      display: flex;
      margin-bottom: 23px;
    }

    .btn {
      //margin-left: -8px;
    }

    .table-orders__row-building {
      padding-right: 10px;
      opacity: 0.3;
    }
  }

  .table-orders__arrow {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background: url('../assets/svg/arrow_green_right.svg') center / 7px no-repeat;
    transform: rotate(90deg);
    position: absolute;
    right: 20px;
  }

  .table-orders__search {
    position: absolute;
    right: 10px;
    width: 40px;
    height: 40px;
    background: url('../assets/svg/Search_24.svg') center / 22px no-repeat;
    cursor: pointer;
  }

  .table-orders__body-head.not-collapsed {
    .table-orders__arrow {
      transform: rotate(-90deg);
    }
  }

  .sphere {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 30px;
  }


  @media (max-width: 767px) {
    .table-orders__header,
    .table-orders__body-head {

    }

    .table-orders__body-desc {
      padding: 25px 15px 30px;
    }

    .table-orders__header p:first-child {
      width: 30px;
      padding-right: 5px;
    }

    .table-orders__header p:nth-child(2) {
      width: 49%;
    }

    .table-orders__row p:first-child {
      width: 30px;
      padding-right: 5px;
    }

    .table-orders__row p:nth-child(2) {
      width: 49%;
    }

    .table-orders__body-desc .table-tariff__row {
      margin-bottom: 22px;
    }

    .table-orders__arrow {
      right: 7px;
    }
  }
</style>
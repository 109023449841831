<template>
   <div>
      <form @submit.prevent="submitAddress">
         <div class="tab-pane__title">
            1/4. Адресные данные клиента
         </div>

         <div class="tab-pane__input">
             <label class="star">Город</label>
             <!--            <input type="text"-->
             <!--                   :disabled="formDisabled"-->
             <!--                   v-model.trim="formCRM.city"-->
             <!--                   :class="{ invalid: validCity}"-->
             <!--            >-->

             <VueBootstrapTypeAhead
                     class="addr-item"
                     :data="addrItemsToShow('showAllCities', 'city')"
                     v-model="formAddress.city.addressSearch"
                     :max-matches="40"
                     :serializer="s => s.value"
                     placeholder="Введите город..."
                     @hit="hitCity"
                     @input="updateCities"
                     :input-class="validCity ? 'invalid' : ''"
                     :show-all-results="true"
                     :disabled="formDisabled"
                     ref="cityInput"
             >
                 <template slot="suggestion" slot-scope="{ data, htmlText }">
                     <div class="d-flex flex-column align-items-baseline">

                         <!-- Note: the v-html binding is used, as htmlText contains
															the suggestion text highlighted with <strong> tags -->
                         <span class="ml-1" v-html="htmlText"></span>
                         <div class="addr-item__additional d-block mt-2 ml-1 font-italic">{{ data.data.region }}</div>
                     </div>
                 </template>

                 <template slot="aftersuggestion">
                     <div v-show="!ui.showAllCities && formAddress.city.addressChoices.length > 0 && hasNotInListSuggestions('city')"
                          class="no-unfocus addr-item__not-in-list ml-1">
                         <a class="no-unfocus" href="javascript://" style="display:block; width: 100%; height: 100%;"
                            @click="ui.showAllCities = true">Нет в списке</a>
                     </div>
                 </template>
             </VueBootstrapTypeAhead>
             <div class="spinner__wrap">
                 <b-spinner v-show="loaders.city" variant="success" label="Spinning"></b-spinner>
             </div>
         </div>

          <div class="tab-pane__input">
              <label>Улица</label>
              <!--            <input type="text" :disabled="formDisabled">-->
              <VueBootstrapTypeAhead
                      class="addr-item"
                      :data="addrItemsToShow('showAllStreets', 'street')"
                      v-model="formAddress.street.addressSearch"
                      :min-matching-chars="1"
                      :serializer="s => s.value"
                      placeholder="Введите улицу..."
                      @hit="formAddress.street.selected = $event.data.fiasguid; formAddress.street.selectedGuid = $event.data.guid; formAddress.street.selectedText = $event.value"
                      @input="updateStreets"
                      input-class="addr-input"
                      :disabled="formDisabled"
                      ref="streetInput"
              >
                  <template slot="aftersuggestion">
                      <div class="addr-item__not-in-list no-unfocus"
                           v-show="!ui.showAllStreets && formAddress.street.addressChoices.length > 0 && hasNotInListSuggestions('street')"
                           style="">
                          <a class="no-unfocus" href="javascript://" style="display:block; width: 100%; height: 100%;"
                             @click="ui.showAllStreets = true">Нет в списке</a>
                      </div>
                  </template>
              </VueBootstrapTypeAhead>
              <div class="spinner__wrap">
                  <b-spinner v-show="loaders.street" variant="success" label="Spinning"></b-spinner>
              </div>
          </div>

          <div class="tab-pane__input">
              <label class="star">Дом</label>
              <!--            <input type="text"-->
              <!--                   :disabled="formDisabled"-->
              <!--                   v-model.trim="formCRM.house"-->
              <!--                   :class="{ invalid: validHouse }"-->
              <!--            >-->
              <VueBootstrapTypeAheadModded
                      class="addr-item"
                      :data="addrItemsToShow('showAllHouses', 'house')"
                      v-model="formAddress.house.addressSearch"
                      :min-matching-chars="1"
                      :maxMatches="100"
                      :serializer="s => s.value"
                      placeholder="Введите номер дома..."
                      @hit="hitHouse"
                      @input="houseRetrieving = true; updateHouses()"
                      :input-class="validHouse ? 'invalid' : ''"
                      :disabled="formDisabled"
                      :show-all-results="true"
                      ref="houseInput"
              >
                  <template slot="aftersuggestion">

                      <a style="display: block;" href="javascript://"
                         v-show="!ui.showAllHouses && formAddress.house.addressChoices.length > 0 && hasNotInListSuggestions('house')"
                         class="no-unfocus addr-item__not-in-list" @click="ui.showAllHouses = true">
                          <!--                          <a class="no-unfocus" href="javascript://" style="display:block; width: 100%; height: 100%;"-->
                          <!--                             @click="ui.showAllHouses = true">Нет в списке</a>-->
                          Нет в списке
                      </a>

                  </template>
              </VueBootstrapTypeAheadModded>
              <div class="spinner__wrap">
                  <b-spinner v-show="loaders.home" variant="success" label="Spinning"></b-spinner>
              </div>
          </div>

          <div v-if="!addressData" class="tab-pane__input">
              <label></label>
              <div class="two-btns">

                  <button class="btn">Подтвердить</button>
                  <button v-if="!formDisabled && needShowNotInListButton" @click="$emit('aist')"
                          class="btn btn-notinlist">Нет в списке
                  </button>
              </div>

          </div>

          <div v-if="addressData" :class="['tab-pane__input', addressInfo.connected ? 'checked': 'unchecked']">
              <label></label>
              <div v-if="addressInfo.connected">Дом подключен</div>
              <div v-else>Дом не подключен</div>
          </div>
      </form>

      <form v-if="addressData"
            @submit.prevent="submitAddress2"
            :class="{disabled: formDisabled}">

         <div class="tab-pane__inputs"></div>

         <div class="tab-pane__input">
             <label :class="{star: !addressInfo.is_private}" for="front">Подъезд</label>
            <input id="front"
                   type="number"
                   :disabled="formDisabled"
                   v-model.trim="addressInfo.porch"
                   :class="{ invalid: validFront }"
                   v-mask="'####'"
            >
         </div>
         <div class="tab-pane__input">
            <label for="level">Этаж</label>
            <input id="level"
                   type="number"
                   :disabled="formDisabled"
                   v-model="addressInfo.floor"
                   v-mask="'####'"
            >
         </div>
         <div class="tab-pane__input">
             <label :class="{star: !addressInfo.is_private}">Квартира</label>
            <input type="text"
                   :disabled="formDisabled"
                   v-model.trim="addressInfo.apartment"
                   :class="{ invalid: validApartment }"
                   maxlength="7"
            >
         </div>

         <div class="tab-pane__input tab-pane__input_text">
            <label>Комментарий <br> по дому</label>
             <div class="tab-pane__input-comment">{{ addressInfo.home_comment }}</div>
         </div>
          <div class="tab-pane__input tab-pane__input_text">
              <label>Комментарий <br> УК/ТСЖ</label>
              <div class="tab-pane__input-comment">
                  {{ addressInfo.company_comment }}
              </div>
          </div>
         <div class="tab-pane__input tab-pane__input_text flex-wrap">
            <label>Частный сектор</label>
            <div class="tab-pane__input-radio">

               <div class="input-radio">
                   <input id="no"
                          type="radio"
                          name="sector"
                          :value="false"
                          v-model="addressInfo.is_private"
                          :disabled="formDisabled || (formAddress.house.is_private && !formAddress.house.aist)"
                   >
                  <label for="no">Нет
                     <label></label>
                  </label>
               </div>

               <div class="input-radio">
                   <input id="yes"
                          type="radio"
                          name="sector"
                          :value="true"
                          v-model="addressInfo.is_private"
                          :disabled="formDisabled || (formAddress.house.is_private && !formAddress.house.aist)"
                   >
                   <label for="yes">Да
                       <label></label>
                   </label>
               </div>

                <!--               <v-selectize class="select"-->
                <!--                            label="text"-->
                <!--                            v-model="addressInfo.is_private"-->
                <!--                            :options="selectOptions"-->
                <!--               />-->

                <b-form-select class="select" :options="selectOptions" v-model="addressInfo.is_private"
                               :disabled="formDisabled || formAddress.house.is_private_origin"></b-form-select>
            </div>
         </div>

         <div class="tab-pane__input">
             <div class="two-btns">
                 <button v-if="!formDisabled" type="submit" class="btn">Подтвердить</button>
                 <div v-else class="btn" @click="change">Изменить</div>
                 
                 <button v-if="!formDisabled && needShowNotInListButton" @click="$emit('aist')"
                         class="btn btn-notinlist">Нет в списке
                 </button>
             </div>
         </div>

      </form>

       <!--      <div v-if="formDisabled" class="request__clients">-->

       <!--         <div class="tab-pane__title">Клиенты</div>-->
       <!--         <p v-if="false">По данному адресу ранее подключений не было.</p>-->

       <!--&lt;!&ndash;         <ClientsTableCRM v-else/>&ndash;&gt;-->

       <!--         <div @click="GO_TO_PAGE_CRM(['pageFormCRM', 'pageFormCRM2'])"-->
       <!--              class="btn">Заявка новому-->
       <!--         </div>-->
       <!--      </div>-->
   </div>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters, mapMutations} from "vuex";
// import ClientsTableCRM from "@/components/requestCRM/ClientsTableCRM";
import {debounce} from "lodash";
import API from "@/api";
import axios from "axios";
import VueBootstrapTypeAhead from "vue-typeahead-bootstrap";
import VueBootstrapTypeAheadModded from "vue-typeahead-bootstrap-modded";

export default {
    name: "pageFormCRM",
    components: {
        VueBootstrapTypeAhead,
        VueBootstrapTypeAheadModded
    },
    data() {
        const emptyAddr = this.emptyAddrItem()
        const house = {
            ...emptyAddr,
            is_private_origin: null,
            is_private: null,
            aist: false,
        }
        const street = {
            ...emptyAddr,
            children: [house]
        }
        const city = {
            ...emptyAddr,
            children: [street, house],
            regionId: null,
        }
        return {


            ui: {
                showAllHouses: false,
                showAllCities: false,
                showAllStreets: false,
            },

            houseRetrieving: false,

            formAddress: {
                city,
                street,
                house,
                apartment: '',
            },

            loaders: {
                city: false,
                street: false,
                home: false,
            },

            radioInput: 'Нет',
            selectOptions: [{text: 'Да', value: true}, {text: 'Нет', value: false}],
            breadcrumb: [
                {
                    text: 'Главная страница',
                    to: {name: 'home'}
                },
                {
                    text: 'Заявка на подключение Wifire',
                    active: true
                }
            ],
      }
   },

   computed: {
       ...mapGetters('CRM', [
           'formCRM',
           'formCRMNext',
           'addressData',
           'formDisabled',
           'addressInfo'
       ]),

     ...mapGetters('test', [
       'connections'
     ] ),
       validCity() {
           return (this.$v.formAddress.city.$error && this.$v.formAddress.city.$dirty)
       },
       validHouse() {
           return this.$v.formAddress.house.$error && this.$v.formAddress.house.$dirty
       },
       validFront() {
           return this.$v.addressInfo.porch.$error && this.$v.addressInfo.porch.$dirty
       },
       validApartment() {
           return this.$v.addressInfo.apartment.$error && this.$v.addressInfo.apartment.$dirty
       },

       needShowNotInListButton() {
           return this.formAddress.city.selected
               && !!this.formAddress.house.addressSearch
               && !this.housesToShow.length
               //&& !this.loaders.home
               && !this.houseRetrieving

       },

       addressAsText() {
           const addressText = [
               this.formAddress.city.selectedText,
               this.formAddress.street.selectedText,
               this.formAddress.house.selectedText,
           ]

           if (this.addressInfo.apartment !== '') {
               addressText.push(`кв. ${this.addressInfo.apartment}`)
           }

           return addressText
               .filter((item) => item !== '')
               .join(', ')
       },

       hasNotInListSuggestions() {
           return (formAddressField) => {
               console.log(formAddressField, ':', this.formAddress[formAddressField].addressChoices.findIndex((item) => item.data.category !== ""))
               return this.formAddress[formAddressField].addressChoices.findIndex((item) => item.data.category !== "") > -1
           }
       },

       addrItemsToShow() {
           return (uiField, formAddressField) => {
               if (this.ui[uiField]) {
                   return [...this.formAddress[formAddressField].addressChoices]
               } else {
                   return [
                       ...this.formAddress[formAddressField].addressChoices.filter((val) => {
                           return val.data.category === ""
                       }),
                   ]
               }
           }
       },

       housesToShow() {
           if (this.ui.showAllHouses) {
               return this.formAddress.house.addressChoices
           } else {
               return [
                   ...this.formAddress.house.addressChoices.filter((val) => val.data.category === ""),
               ]
           }
       },
   },

   validations: {
       formAddress: {
           //city: {required},
           city: {
               selectedGuid: {required},
           },
           house: {
               selectedGuid: {required},
           },
       },
       addressInfo: {
           porch: {requiredIf: requiredIf(data => !data.is_private)},
           apartment: {requiredIf: requiredIf(data => !data.is_private)},
       },
       formCRMNext: {
           front: {required},
           apartment: {required},
       }
   },

   methods: {
       ...mapMutations('CRM', [
           'BTN_FORM_DISABLED',
           'GO_TO_PAGE_CRM',
           'SET_ADDRESS_INFO',
           'DISABLE_TABS_EXCEPT'
       ]),
      submitAddress() {
          if (this.$v.formAddress.$invalid) {
              this.$v.formAddress.$touch()
          } else {
              this.$store.commit('CRM/SUBMIT')
              this.$v.$reset()
              this.DISABLE_TABS_EXCEPT('address')
          }

      },
      submitAddress2() {
          if (this.$v.formAddress.$invalid || this.$v.addressInfo.$invalid) {
              console.log('invalid')
              this.$v.addressInfo.$touch()
              this.$v.formAddress.$touch()
          } else {
              this.$store.commit('CRM/SHOW_CLIENTS')
              this.$emit('search', {
                  fiascode: this.formAddress.house.selectedGuid,
                  flat: this.addressInfo.apartment ? this.addressInfo.apartment : null,
              })
              this.SET_ADDRESS_INFO({
                  addressText: this.addressAsText
              })
              this.$v.$reset()
          }
      },

       hitCity($event) {
           this.formAddress.city.selected = $event.data.fiasguid
           this.formAddress.city.selectedGuid = $event.data.guid
           this.formAddress.city.selectedText = $event.value
           this.formAddress.city.regionId = $event.data.regionId
       },

       hitHouse($event) {
           // console.log($event)
           //  if ($event.data.guid === -1) {
           //      this.ui.showAllHouses = true
           //      this.formAddress.house = this.emptyAddrItem()
           //      console.log($event)
           //      return false
           //  }

           this.formAddress.house.selected = $event.data.fiasguid;
           this.formAddress.house.selectedGuid = $event.data.guid;
           this.formAddress.house.selectedText = $event.value
           this.formAddress.house.is_private_origin = $event.data.aist
           this.formAddress.house.is_private = $event.data.gm_isprivatedistrict === 'true'
           this.formAddress.house.aist = $event.data.aist

           const is_private = this.formAddress.house.aist ? true : $event.data.gm_isprivatedistrict === 'true'

           this.SET_ADDRESS_INFO({
               home_comment: $event.data.gm_basicinformation,
               company_comment: $event.data.gm_managementcompanycomment,
               addressGuid: $event.data.guid,
               connected: $event.data.connected === "true",
               regionId: this.formAddress.city.regionId,
               localityId: this.formAddress.city.selectedGuid,
               is_private: is_private,
               is_private_orig: this.formAddress.house.is_private_origin,
           })

           console.log($event)
       },

       resetChildren(item) {
           if (item.children === undefined) return
           const emptyItem = this.emptyAddrItem()
           item.children.forEach((child, idx) => {
               Object.keys(emptyItem).forEach((key) => {
                   item.children[idx][key] = emptyItem[key]
               })
           })

       },

       emptyAddrItem() {
           return {
               addressSearch: '',
               selected: null,
               selectedGuid: null,
               selectedText: '',
               addressChoices: [],
           }
       },

       sortAddrItems(a, b) {
           const nameA = a.value.toLowerCase(), nameB = b.value.toLowerCase();
           if (a.data.aist) {
               return 1;
           }
           if (nameA.length < nameB.length) {
               return -1;
           }
           if (nameA.length > nameB.length) {
               return 1;
           }
           return 0;
       },

       updateCities: debounce(async function () {
           //this.city.selected = this.emptyAddrItem().selected
           this.resetChildren(this.formAddress.city)
           this.ui.showAllCities = false
           this.loaders.city = true
           const existItem = this.formAddress.city.addressChoices.find((item) => item.value === this.formAddress.city.addressSearch)
           if (!existItem) {
               //console.log(existItem)
               await API.xdemands.findCities(this.formAddress.city.addressSearch)
                   .then(r => r.data)
                   .then((r) => {
                       this.formAddress.city.addressChoices = r.suggestions.sort(this.sortAddrItems)
                   })
                   .catch((e) => {
                       if (axios.isCancel(e))
                           console.log('canceled')

                       console.log(e)
                   })
           }

           this.loaders.city = false
       }, 1000),

       updateStreets: debounce(async function () {
           //this.street.selected = this.emptyAddrItem().selected

           this.loaders.street = true
           const existItem = this.formAddress.street.addressChoices.find((item) => item.value === this.formAddress.street.addressSearch)
           if (!existItem) {
               this.resetChildren(this.formAddress.street)
               this.ui.showAllStreets = false
               await API.xdemands.findStreets(this.formAddress.street.addressSearch, this.formAddress.city.selected, this.formAddress.city.selectedGuid)
                   //.then((r) => { console.log(r) })
                   .then(r => r.data)
                   .then((r) => {
                       this.formAddress.street.addressChoices = r.suggestions.sort(this.sortAddrItems)
                   })
                   .catch((e) => {
                       if (axios.isCancel(e))
                           console.log('canceled')

                       console.log(e)
                   })
           }
           this.loaders.street = false
       }, 1000),

       updateHouses: debounce(
           async function () {
               //this.house.selected = this.emptyAddrItem().selected

               this.loaders.home = true
               //alert(this.formAddress.house.addressSearch)
               const existItem = this.formAddress.house.addressChoices.find((item) => item.value === this.formAddress.house.addressSearch)
               //const existItem = false
               if (!existItem) {
                   this.resetChildren(this.formAddress.house)
                   this.ui.showAllHouses = false
                   await API.xdemands.findHouses(this.formAddress.house.addressSearch, this.formAddress.city.selected, this.formAddress.street.selected, this.formAddress.city.selectedGuid, this.formAddress.street.selectedGuid)
                   //.then((r) => { console.log(r) })
                       .then(r => r.data)
                       .then((r) => {
                           this.formAddress.house.addressChoices = r.suggestions.sort(this.sortAddrItems)
                       })
                       .catch((e) => {
                           if (axios.isCancel(e))
                               console.log('canceled')

                           console.log(e)
                       })
                       .finally(() => {
                           this.houseRetrieving = false
                       })
           }
           this.loaders.home = false
       }, 1000),

       change() {
           this.BTN_FORM_DISABLED()
           this.$emit('change')
       },

       // Хак для отслеживания и сброса ввода несуществующего адреса
       applyOnBlurBehavior(inputRefs) {
           inputRefs.forEach((input) => {
               this.$refs[input + 'Input'].$el.querySelector('input').addEventListener('blur', (e) => {
                   // в случае если blur происходит при переходе на селект с выбором адресов - ничего не делаем, так как это событие происходит раньше чем @hit
                   if (e.relatedTarget?.classList?.contains('vbst-item')) {
                       return true
                   }

                   this.formAddress[input].addressSearch = this.formAddress[input].selectedText
                   return true
               })


           })
       },
   },

    reset() {
        console.log('reset')
    },

    created() {
        //this.applyOnBlurBehavior(['city', 'street'])
        this.$on('reset', () => {
            Object.assign(this.$data, this.$options.data.apply(this))
        })
    }
}
</script>

<style lang="scss" scoped>
.select {
  display: none;
  max-width: 290px;
}

.wrapper_addr_item {
  width: 100%;
}

.addr-item {
  width: 100%;
}

.two-btns {
  display: flex;
  justify-content: space-between;
  width: 310px;


}

.btn.btn-notinlist {
  background-color: var(--mf-gray);
}

.addr-item__not-in-list {
  padding: 10px 10px;
  background-color: white;
  margin-top: -3px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.spinner__wrap {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.addr-item .input-group input.form-control {
  width: 100%;
  max-width: 290px;
  height: 40px;
  line-height: normal;
  padding: 0 14px;
  border: 1px solid #b2b2b2;
  color: #333;
  border-radius: 0;

  &:hover, &:focus {
    border-color: #333;
    box-shadow: none;
  }
}

@media (max-width: 767px) {

  .request .tab-pane__input:not(.tab-pane__input_text) > label,
  .request .tab-pane__input.tab-pane__input_text.flex-wrap > label {
    width: 100%;
    margin-bottom: 10px;
  }


  .tab-pane__input-radio {
    width: 100%;
    flex-wrap: wrap;
  }

  .spinner__wrap {
    padding: 10px 5px;
  }

  .request__clients .tab-pane__title {
    margin-bottom: 20px;
  }

  .input-radio {
    display: none;
  }

  .select {
    display: block;
  }

  .two-btns {
  }
}

</style>
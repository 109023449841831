<template>
   <div class="page-form-FMC7 request__info">
      <div class="tab-pane__title">
          7/7. Сводная информация
      </div>

       <div class="info__content">
           <div v-for="item in data" :key="item.name" class="info__row">
               <p v-html="item.name"></p>
               <p v-html="item.text"></p>
               <p v-if="item.table">
                <table class="table responsive">
                    <thead>
                    <tr>
                        <th v-for="(headrow, ihead) of item.table.header" :key="ihead">{{ headrow }}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(bodyrow, ibody) of item.table.body" :key="ibody">
                            <td v-for="(cell, icell) of bodyrow"  :key="icell" :data-label="item.table.header[icell]">
                                {{ cell }}
                            </td>
                        </tr>
                    </tbody>
                </table>
               </p>
           </div>
       </div>

       <div class="btns">
           <div class="row-btn-back">
               <div @click="GO_TO_PAGE_FMC(['pageFormFMC7','pageFormFMC6'])"
                    class="btn back">Назад
               </div>
           </div>
           <div class="btn cancel" @click="cancel">Отменить</div>
           <div class="btn" @click="createDemand"
           >Создать заявку
           </div>
           <br>

           <ModalCongratulations
                   :inquiry-id="inquiryId"
                   @newRequest="$router.go(0)"
           />
           <!--           <ModalChooseDate :inquiry-id="inquiryId"/>-->
           <!--           <ModalConnection/>-->
           <!--           <ModalConnectionAssigned/>-->
       </div>
   </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {showModalErrorDefault} from "@/utils/errors";
import ModalCongratulations from "@/components/modals/requestCRM/ModalCongratulations";


export default {
    name: "pageFormFMC7",
    data() {
        return {
            inquiryId: '',
        }
    },
    components: {
        ModalCongratulations,
    },
    computed: {
        ...mapGetters({data: 'summary', createDemandRequest: 'createDemandRequest', addressInfo: 'addressInfo'}),
    },
    methods: {
        ...mapMutations([
            'GO_TO_PAGE_FMC',
            'NEW_REQUEST_FMC',
        ]),
        ...mapActions(['doCreateDemand']),
        cancel() {
            this.$router.push({name: 'home'})
            this.NEW_REQUEST_FMC()
        },

        async createDemand() {
           console.log(this.addressInfo)
            await this.doCreateDemand(this.createDemandRequest)
                .then(r => r.data)
                .then((r) => {
                    this.inquiryId = r.inquiryId
                    this.$bvModal.show('modalCongratulations')
                })
                .catch(e => showModalErrorDefault(e.message))

        }
    }

}
</script>

<style lang="scss" scoped>
.request__info {

   .info__content {
      padding-top: 10px;
      margin-bottom: 25px;
   }

   .info__row {
      display: flex;
      margin-bottom: 27px;
      line-height: 20px;

      &:last-child {
         margin-bottom: 0;
      }

      p:first-child {
         width: 182px;
         flex-shrink: 0;
      }
   }

   .btns {
      display: flex;
      flex-wrap: wrap;
   }

   .row-btn-back {
      margin-right: auto;
   }

   .btn.cancel {
      margin-right: 20px;
   }

   @media (max-width: 767px) {

      .info__row {
         p:first-child {
            width: 150px;
         }

          .responsive thead {
              visibility: hidden;
              height: 0;
              position: absolute;
          }

          .responsive tr {
              display: block;
              margin-bottom: .625em;
          }

          .responsive td {
              border: none;
              display: block;
              font-size: .8em;
              padding: 0;
          }

          .responsive td::before {
              content: attr(data-label);
              float: left;
              width: 100px;
              font-weight: bold;
          }
      }

      .btns {
         justify-content: space-between;
      }

      .btn:not(.back) {
         margin-bottom: 30px;
      }

      .row-btn-back {
         order: 1;
         width: 100%;
      }
   }

}
</style>